/**
  * important: this styles will never change, so to change it override on the theme folder.
  * important: to add new class here ask for the project lead before or do it on the theme folder.
  */

// Breakpoint Mixins
// Usage: $device-name (string), breakpoint (integer), unit (string).

// Usage: @include smallscreen{ ... }
@mixin smallscreen {
  @media only screen and (max-width: #{$mobile}#{$unit}) {
    @content;
  }
}

// Usage: @include mobile{ ... }
@mixin mobile {
  @media only screen and (min-width: #{$mobile}#{$unit}) {
    @content;
  }
}

// Usage: @include max-mobile{ ... }
@mixin max-mobile {
  @media only screen and (max-width: #{$mobile}#{$unit}) {
    @content;
  }
}

// Usage: @include mobile-tablet{ ... }
@mixin mobile-tablet {
  @media only screen and (min-width: #{$mobile}#{$unit}) and (max-width: #{$tablet}#{$unit}) {
    @content;
  }
}

// Usage: @include tablet{ ... }
@mixin tablet {
  @media only screen and (min-width: #{$tablet}#{$unit}) {
    @content;
  }
}

// Usage: @include tablet-laptop{ ... }
@mixin tablet-laptop {
  @media only screen and (min-width: #{$tablet}#{$unit}) and (max-width: #{$laptop}#{$unit}) {
    @content;
  }
}

// Usage: @include max-laptop{ ... }
@mixin max-laptop {
  @media only screen and (max-width: #{$laptop}#{$unit}) {
    @content;
  }
}

// Usage: @include laptop{ ... }
@mixin laptop {
  @media only screen and (min-width: #{$laptop}#{$unit}) {
    @content;
  }
}

// Usage: @include laptop-desktop{ ... }
@mixin laptop-desktop {
  @media only screen and (min-width: #{$laptop}#{$unit}) and (max-width: #{$desktop}#{$unit}) {
    @content;
  }
}

// Usage: @include desktop{ ... }
@mixin desktop {
  @media only screen and (min-width: #{$desktop}#{$unit}) {
    @content;
  }
}

// Usage: @include desktop-bigscreen{ ... }
@mixin desktop-bigscreen {
  @media only screen and (min-width: #{$desktop}#{$unit}) and (max-width: #{$bigscreen}#{$unit}) {
    @content;
  }
}

// Usage: @include bigscreen{ ... }
@mixin bigscreen {
  @media only screen and (min-width: #{$bigscreen}#{$unit}) {
    @content;
  }
}


// Browsers Mixins
// Usage: @include IE{ ... }
@mixin IE {

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @content;
  }
}

// Usage: @include IOS{ ... }
@mixin IOS {
  @supports (-webkit-overflow-scrolling: touch) {
    @content;
  }
}

// Usage: @include MSE{ ... }
@mixin MSE {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Containers
.theme-container {
  margin: 0 auto;
  // TODO: Removed because it was breaking a lot of pages. Check to fix it.
  // max-width: #{$mobileContainer}#{$unit};

  @include tablet {
    max-width: #{$tabletContainer}#{$unit};
    width: 100%;
  }

  @include laptop {
    max-width: #{$laptopContainer}#{$unit};
  }

  @include desktop {
    max-width: #{$desktopContainer}#{$unit};
  }
}
