.home-main {
  @include flex(column, center, center, unset);

  .home-main__content,
  .home-main__content--center {
    @include flex(column, center, center, unset);

    .home-main__btn-container {
      margin-bottom: 1rem;
    }

    .hide {
      display: none;
    }

    .home-main__login {
      width: 100%;

      .profile {
        width: auto;
      }
    }
    
    .swiper {
      max-height: 100%;
    }

    .swiper-pagination-bullet {
      width: 15px;
      height: 15px;
      background-color: white;
      border: 4px solid;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: $primary-colour;
      border: 4px solid;
    }

    .my-custom-pagination-div {
      position: absolute;
      right: 5%;
      bottom: 7%;
      left: auto;
      z-index: 1;
      width: auto;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
    }

    .swiper-wrapper {
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
    }

    .swiper-slide {
      margin-bottom: 1rem;
      @include flex(column, center, center, unset);
      margin-top: 1rem;
      position: relative;
      -webkit-backface-visibility: hidden; 
      -webkit-transform: translate3d(0, 0, 0);  

      .swiper-slide__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

    }


    .home-main__content__left {
      @include flex(column, center, center, unset);
      width: 100%;

      @include laptop {
        width: 50%;
      }

      .home-main__content__left__title {
        margin-top: 2rem;
        min-width: 300px;

        @include laptop {
          margin: 20px 0 16px;
        }
      }

    }

    .home-main__content__left__swiper {
      width: 100%;
      margin-bottom: 3rem;

      @include laptop {
        width: 90%;
      }
    }

  }

  .home-main__content {
    width: 80%;

    @include laptop {
      flex-direction: row;
      align-items: start;
      width: 100%;

      .home-main__login {
        border-left: 1px $grey-10 solid;
        flex: 1 1;
      }

    }

    @include desktop {
      width: 90%;
    }

    @include bigscreen {
      width: 80%;
    }
  }

  .home-main__content--center {
    width: 80%;

    .home-main__content--center--logged {
      width: 100%;
      .home-main__content__left__swiper {
        @include desktop {
          width: 50%;
        }
      }
    }
  }
}
