.current-restaurant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .current-restaurant__close {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @include font-icon('fas', 'before', '\f00d', unset, unset, unset, unset) {
      @extend .txt-xs;
      @extend .txt-black;
    }

  }

  .current-restaurant__wrap-img {
    margin: 1rem;
    position: relative;
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 4px 2px rgba(212, 212, 212, 0.75);
    -moz-box-shadow: 0px 0px 4px 2px rgba(212, 212, 212, 0.75);
    box-shadow: 0px 0px 4px 2px rgba(212, 212, 212, 0.75);

    .current-restaurant__wrap-img__image {
      @include middle-image;
      width: auto;
      height: 100%;
    }

  }


  .current-restaurant__paragraph {
    text-align: center;
    margin: 1rem;

    .current-restaurant__paragraph--text  {
      margin: 0
    }
  }

  .current-restaurant--link {
    text-decoration: none;
    width: 100%;
    display: flex;
  }


}

.unselected {
  .unselected--text {
    text-align: center
  }
}
