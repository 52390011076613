.product-actions {
  margin: 1.5rem auto 2rem;

  &--sticky {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    background-color: $white;
    box-shadow: 0 -3px 6px 0 rgb(0 0 0 / 10%);
    padding: rem(20);

    @include max-mobile {
      .product-actions__total {
        font-size: rem(14);
        margin: 0 rem(10) 0 0;
      }
    }

    .product-actions__container {
      @include flex(unset, space-between, center, wrap);
      width: 100%;
    }
  }
}
