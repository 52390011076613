.basket-header {
  padding: 0;
  border-bottom: 1px solid $secondary-colour;

  .basket-header__title {
    &.notification.notification--left[data-notification]::after {
      top: -10px;
      left: -12px;
    }
  }
}
