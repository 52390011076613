.quantity-picker__container {
  max-width: 112px;
  min-height: 46px;
  display: flex;
  align-items: center;
  margin-right: 16px;


  .quantity-picker__input {
    max-width: 105px;
    text-align: center;
  }

  .form-control {
    justify-content: space-between;
    display: flex;
    align-items: center;

    &-quantity {
      @extend .txt-bold;
      margin-left: .62rem;
      margin-right: .62rem;
    }

    &-quantity-center {
      @extend .txt-bold;
      margin-left: 2.5rem;
      margin-right: 2.5rem;
    }

    .form-control__btn {
      cursor: pointer;
      border-radius: 50%;
      height: rem(30);
      width: rem(30);
      line-height: 1;
      padding: 0;
      outline: none;
      border: 2px solid $primary-colour;
      background-color: transparent;
      color: $primary-colour;
      transition: 0.3s;
      @include flex(row, center, center, unset);

      &:disabled {
        opacity: .4;
        cursor: default;
        color: $grey-40;
        border-color: $grey-40;
      }

      &:hover {
        background-color: $primary-colour;
        color: $tertiary-colour;

        &:disabled {
          background-color: transparent;
          color: $grey-40;
          border-color: $grey-40;
        }
      }

      &:active {
        background-color: $secondary-colour;
        color: $tertiary-colour-dark;
        border-color: $secondary-colour;
      }
    }

    &__btn-more {
      @include font-icon('fas', 'after', '\f067', unset, unset, unset, unset) {
        @extend .txt-xs;
      }
    }

    &__btn-minus {
      @include font-icon('fas', 'after', '\f068', unset, unset, unset, unset) {
        @extend .txt-xs;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
  }
}
