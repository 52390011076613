// FONT AWESOME ------------------------------------------

@import "./../../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "./../../../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "./../../../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "./../../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "./../../../../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims.scss";

@font-face {
  font-family: 'Font Awesome 5 Regular';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./../../../../../src/assets/fonts/font-awesome/fa-regular-400.eot");
  src: url("./../../../../../src/assets/fonts/font-awesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("./../../../../../src/assets/fonts/font-awesome/fa-regular-400.woff2") format("woff2"), url("./../../../../../src/assets/fonts/font-awesome/fa-regular-400.woff") format("woff"), url("./../../../../../src/assets/fonts/font-awesome/fa-regular-400.ttf") format("truetype"), url("./../../../../../src/assets/fonts/font-awesome/fa-regular-400.svg#fontawesome") format("svg");
}

// .far {
//   font-family: 'Font Awesome 5 Regular';
//   font-weight: 400;
// }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./../../../../../src/assets/fonts/font-awesome/fa-brands-400.eot");
  src: url("./../../../../../src/assets/fonts/font-awesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("./../../../../../src/assets/fonts/font-awesome/fa-brands-400.woff2") format("woff2"), url("./../../../../../src/assets/fonts/font-awesome/fa-brands-400.woff") format("woff"), url("./../../../../../src/assets/fonts/font-awesome/fa-brands-400.ttf") format("truetype"), url("./../../../../../src/assets/fonts/font-awesome/fa-brands-400.svg#fontawesome") format("svg");
}

// .fab {
//   font-family: 'Font Awesome 5 Brands';
//   font-weight: 400;
// }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("./../../../../../src/assets/fonts/font-awesome/fa-solid-900.eot");
  src: url("./../../../../../src/assets/fonts/font-awesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("./../../../../../src/assets/fonts/font-awesome/fa-solid-900.woff2") format("woff2"), url("./../../../../../src/assets/fonts/font-awesome/fa-solid-900.woff") format("woff"), url("./../../../../../src/assets/fonts/font-awesome/fa-solid-900.ttf") format("truetype"), url("./../../../../../src/assets/fonts/font-awesome/fa-solid-900.svg#fontawesome") format("svg");
}

// .fa,
// .fas {
//   font-family: 'Font Awesome 5 Free';
//   font-weight: 900;
// }
