.search-engine-wrapper {
  .search-engine {
    .search-input {
      border-radius: 2px;
      padding: rem(5) rem(5) rem(5) rem(45);
      font-family: "Mr Eaves", sans-serif;
      font-size: rem(25);
      box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0.1px;

      &:focus {
        border: 1px solid black !important;
      }
    }
  }
}

.search-engine-wrapper .search-engine .search-suggestions__content {
  position: absolute;
  top: 100%;
  margin-top: 5px;
  width: 100%;
  max-height: 280px;
  overflow-y: scroll;
  background-color: $white;
  font-weight: bold;
  z-index: 3;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0.1px;

  .suggestion-product__tile {
    span {
      font-family: "Mr Eaves", sans-serif;
      font-weight: 400;
      font-size: rem(20);
    }
  }
}
