.contact {
  @include flex(column, center, center, unset);
  position: relative;

  .contact__container {
    margin: auto 1rem;

    @include tablet {
      margin: auto 1rem;
    }

    @include flex(column, center, center, unset);
    width: 100%;
    height: 100%;
  }

  .contact__title {
    @extend .txt-bold;
    @extend .txt-uppercase;
    @extend .txt-xxl;
    text-align: center;
  }

  .contact__body {
    margin: auto rem(60);
    @extend .txt-center;
  }

  .contact__link {
    color: $primary-colour;
    @extend .txt-xl;
    @extend .txt-bold;
  }

  .contact__image {
    height: 18%;
    @include absolute(unset, 15%, 0, unset);
  }
}
