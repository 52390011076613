@import './animations';

.restaurants__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: .3s;

  &--sticky-button {
    @include max-laptop {
      z-index: 1;
    }
  }

  @include laptop {
    width: rem(540);
  }

  .restaurants__content {

    @include laptop {
      @include flex(unset, unset, unset, unset);
      flex: 1;
      overflow-y: auto;
    }
  }

  .restaurants__action {
    background-color: $tertiary-colour;
    padding: 2.2rem 0;
    @include flex(unset, center, center, unset);
    transition: .3s;

    &--sticky {
      @include max-laptop {
        position: fixed;
        bottom: -100%;
        width: 100%;
        animation: bottomSlideTop .6s ease-out forwards;
        box-shadow: 0 -3px 6px 0 rgb(0 0 0 / 10%);
      }
    }
  }

  .restaurants__list {
    background-color: $tertiary-colour;
    padding: initial;
    margin: initial;
    width: 100%;
  }
}

.confirm-link {
  text-decoration: none;
  width: 70%;

  .btn__primary {
    margin: auto;
    display: block;
  }
}

.confirm-btn {
  background-color: $primary-colour;
  border-radius: .6rem;
  margin: auto;
  display: block;
  color: $black;
  border: none;
  padding: 0.75rem 3.125rem;
  width: 100%;
  max-width: 340px;
  transition: 0.3s;
  text-transform: uppercase;
  //font-weight: bold;
}
