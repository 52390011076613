.header-product__list {
  display: flex;
  align-items: center;
  // padding: 1.2rem 0 1.6rem 0;
  width: 100%;
  flex-direction: column-reverse;
  @include tablet {
    flex-direction: row;
  }
}

// Product list
.product-list {
  margin: 1rem;
  @include grid(auto, repeat(2, 1fr), rem(26));

  @include tablet {
    @include grid(auto, repeat(3, 1fr), rem(26))
  }

  @include desktop {
    @include grid(auto, repeat(4, 1fr), rem(26))
  }

  @include smallscreen {
    @include grid(auto, repeat(1, 1fr), rem(26))
  }
}

// Product item
.product-item {
  animation: fadeInAnimation ease-in-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  .product-item__container {
    @include flex(column, unset, unset, unset);
    height: 100%;
    flex-basis: 100%;
    background-color: $secondary-colour-dark;
  }

  .product-item__unavailable {
    position: absolute;
    bottom: 15%;
    width: 100%; height: 85%;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 0px;
      width: 100%; height: 34px;
      background-color: #ff0000a6;
    }

    p {
      position: relative;
      font-size: 20px;
      color: white;
      text-transform: uppercase;
    }
  }

  .product-item__image-container {
    overflow: hidden;
    width: 100%;
    height: 230px;
  }

  .product-item__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #F3F1F2;
    transition: all .2s ease-in-out;
  }

  .product-item--infos {
    padding: 12px;
    background-color: $secondary-colour-dark;
    flex-grow: 1;

    .product-item--title {
      margin: 0 0 rem(2) 0;
      color: $secondary-colour-light;
      @extend .txt-center;
    }
  }

  .product-item__button {
    position: relative;
    padding: 0;
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    background-color: $secondary-colour-dark;
    &:focus {
      outline-color: $primary-colour !important;
    }

    &:hover {
      .product-item__image {
        transform: scale(1.1);
      }
    }
  }

  .product-item__choice-button {
    @extend .product-item__button;
    @include flex(column, center, center, unset);
    min-height: rem(279);
    opacity: 1;
    animation: fade-in .2s ease-out forwards;
    .choice-button {
      flex: 1;
      width: 100%;
      background-color: transparent;
      border: none;
      color: $tertiary-colour-light;
      @include flex(row, center, center, unset);
      &:hover {
        cursor: pointer;
        color: $primary-colour-light;
        .button-icon {
          &.button-icon--burger, &.button-icon--meal{
            fill: $primary-colour-light;
          }
        }
      }
      .button-title {
        padding-top: 0;
        @extend .txt-l;
        margin: 0 1.5rem;
      }
      &.choice-button--top {
        border-bottom: 1px solid $tertiary-colour-light;
      }
      &.choice-button--bottom {
        border-bottom: none;
      }
      .button-icon {
        &.button-icon--arrow {
          stroke: $primary-colour-light;
          width: 9px;
        }
        &.button-icon--burger, &.button-icon--meal {
          fill: $tertiary-colour-light;
          width: 20px;
        }
      }
    }
  }

  .product-item--price {
    @include absolute(rem(14), unset, unset, 0);
    background-color: $primary-colour;
    padding: rem(5) rem(10);
    border-top-right-radius: rem(4);
    border-bottom-right-radius: rem(4);
    z-index: 1;
    color: $tertiary-colour;

    span {
      padding-top: rem(2);
      display: block;
    }
  }
}

.hover-parent {
  position: relative;
  .hover-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: none;
    visibility: hidden;
  }
  &:hover, &:active {
    .hover-child {
      display: block;
      visibility: visible;
      animation: fadeInAnimation .2s ease-out forwards;
    }
  }
}
