.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 340px;

  .form__field {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0.6rem;
    max-width: rem(374);
    position: relative;

    i {
      font-style: normal;
      // apply the same padding than a button tag
      // to make no difference with form using button tags to show the eye
      padding: 1px 6px;
    }
  }

  .form__field--eye {
    @extend .form__field;
    margin-top: 0;
  }

  label {
    @extend .txt-xs;
    color: $grey-80;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 340px;
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;

    p {
      margin: 0;
      width: 100%;
      padding-left: 0.4rem;
      text-align: left;
      @extend .txt-xs;
    }
  }

  .form__input {
    border: $input-border;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
    background-color: $input-bg-color;

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:focus {
      outline: 0.1rem solid $grey-60;
    }
  }

  .form__input--error {
    border: 0.1rem solid $error-colour;
    @extend .form__input;

    &:focus {
      outline: 0.1rem solid $error-colour;
    }
  }

  .form__input__container--error {
    height: 18px;
    width: 100%;
  }

  .form__input__password_icon--show,
  .form__input__password_icon--unshow {
    position: absolute;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      &::before {
        color: $primary-colour;
      }
    }
  }

  .form__input__password_icon--show {
    @include font-icon("fas", "before", "\f06e", unset, unset, unset, unset) {
      @extend .txt-s;
      color: $secondary-colour;
    }
  }

  .form__input__password_icon--unshow {
    @include font-icon("fas", "before", "\f070", unset, unset, unset, unset) {
      @extend .txt-s;
      color: $secondary-colour;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    @extend .txt-m;
    color: $grey-80;
  }

  input[type="password"] {
    &::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
    }
  }

  .form__btn {
    margin: 2rem auto;
    @extend .txt-m;
  }

  .m-top__disabled {
    margin-top: 0.5rem!important;
  }

  .txt-error {
    margin: 0.2rem 0 0;
    max-width: rem(374);
    width: 100%;
    text-align: left;
    @extend .txt-xs;
  }

  .txt__container-error {
    height: 28px;
  }
}

.display-none {
  display: none;
}

a {
  text-decoration: none;

  &:hover {
    color: $primary-colour-dark;
  }
}

.form__terms {



  .cards-container {
    padding: 0;

    .checkbox-container {
      margin: 0;
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;

      .form-input__label {
        margin: 0;
        line-height: 1;
      }
    }
  }
}

.form__terms--link {
  cursor: pointer;
  @extend .txt-s;
  @extend .txt-bold;
}

.profile__terms {
  margin-top: 2rem;
  width: 100%;
}
