.delete-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .delete-item__close {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @include font-icon('fas', 'before', '\f00d', unset, unset, unset, unset) {
      @extend .txt-xs;
      @extend .txt-black;
    }
  }

  @include font-icon('fas', 'before', '\f071', unset, unset, unset, unset) {
    @extend .txt-xl;
    @extend .txt-secondary-dark;
    margin: 1rem 0;

    @include tablet {
      margin-bottom: 2rem;
      @include font-size-xxl;
    }
  }

  .delete-item__text {
    text-align: center;
    font-weight: 600
  }

  .delete-item__btn {
    @include flex(unset, center, unset, unset);
    width: 100%;
    cursor: pointer;

    button {
      @include tablet {
        width: 20%;
        max-width: 100%;
      }
    }
  }
}
