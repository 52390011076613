.profile__container {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  width: 100%;

  @include tablet {
    margin: 0 auto;
  }

  .profile-content {
    @include flex(column, flex-start, flex-start, nowrap);

    @include tablet {
      width: 100%;
      @include flex(row, flex-start, flex-start, nowrap);
    }
  }

  .user-profile {
    box-sizing: border-box;
    padding: 1rem 1.7rem;
    width: 100%;
    margin: 0rem 1rem 2.125rem 0rem;
    background-color: $secondary-colour-light;
    position: relative;
    min-height: 16rem;

    .btn-round__primary {
      top: 1.7rem;
      right: 1.7rem;
    }

    @include tablet {
      width: 100%;
    }

    @include laptop {
      width: 50%;
    }

    &__btn {
      @include absolute(10px, 10px, unset, unset);
      @extend .home-main__btn;
      @extend .btn__secondary;
      @extend .btn__secondary--icon-before;
      @extend .btn__secondary--icon-fas;
      @extend .btn-padding;
    }
  }

  .profile__container__reset-password {
    @extend .user-profile;
    width: 100%;
    position: relative;
    cursor: pointer;
    padding: 0;

    @include tablet {
      width: 50%;
    }

    .reset-password__title {
      padding: 1.38rem 1.2rem;
      margin: 0;

      @include font-icon('fas', 'after', '\f078', unset, 1.875rem, unset, unset) {
        @extend .txt-l;
        color: $grey-50;
      }
    }

    &--open {
      &::after {
        display: inline-block;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transition: transform .5s ease;
      }
    }

    .form__reset-password {
      display: none;

      &--open {
        padding: 0.6rem 1.2rem;

        .form-input__container {
          @include flex(column, unset, flex-start, unset);

          .form-input__label {
            @extend .txt-xlight;
            box-sizing: border-box;
            width: 100%;
            color: $grey-40;
            padding-left: inherit;
          }

          .form-input__input {
            box-sizing: border-box;
            width: 90%;
            border: solid 2px $grey-20;
            border-radius: 6px;
          }
        }

        .profile-page__btn--full {
          margin-top: 2.1875rem;
          max-width: 13.125rem;
          padding: 0.75rem 1.6875rem;
        }
      }
    }
  }
}

.profile-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile {
  .profile__title {
    margin-bottom: 1rem;
  }

  .auth__input:-webkit-autofill, .auth__input:-webkit-autofill:hover, .auth__input:-webkit-autofill:focus, .auth__input:-webkit-autofill:active{
    box-shadow: 0 0 0 30px $white inset !important;
  }

  .profile__reset {
    margin: 0.5rem auto;
  }

  .profile__account {
    margin: 2rem auto 1rem auto;
  }

  .auth__sso__methods {
    text-align: center;
    margin: 0 auto;
    .sso__button {
      height: 45px;
      margin-top: 1rem;
      @extend .bg-google;
      padding: 0;
      display: flex;
      border-radius: 5px;
      transition-duration: 0.25s;
      &:hover {
        opacity: 0.8;
      }
      .sso__btn__icon {
        width: 50px;
        padding: 0 4px;
      }
      .sso__btn__label {
        width: 100%; height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .oauth__button {
      border-style: solid;
      border-width: 2px;
      @extend .border-google;
      .sso__btn__icon {
        background-color: white;
        border-radius: 3px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .fb__button {
      @extend .bg-facebook;
      .sso__btn__icon {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .form {
    .form__btn {
      margin: 2rem auto 0;
      min-width: 12rem;
      max-height: 2.9rem;
    }
  }

  .reset-account__btn {
    color: $secondary-colour-dark;
    text-decoration: underline;
    padding: 2rem 0rem;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      opacity: .8;
    }
  }
}
