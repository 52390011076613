.parts {
  margin-top: rem(30);
  // border-top: 2px solid $grey-30;
  padding-top: rem(30);
  width: 100%;
  margin-bottom: rem(50);

  .parts__header {
    @include flex(unset, space-between, center, nowrap);
  }

  .parts__title {
    // max-width: rem(200);
    word-break: break-all;
    margin-right: rem(10);
    font-size: rem(30);
  }
}
