.checkout-page-main {
  background-color: white;
  .checkout-page-left__content-wrapper {
    box-shadow: none;
    border-radius: 0;
  }
}

.checkout-page__title {
  margin-top: rem(50);
}

.checkout-page__restaurant-closed {
  text-align: center;
  margin-top: 4rem;
}

.checkout-page__body {
  display: flex;
  margin: rem(20) auto rem(50) auto;
  flex-direction: column;

  @include laptop {
    flex-direction: row-reverse;
  }
}

.checkout-page__column {
  flex-grow: 1;

  @include laptop {
    box-sizing: border-box;
  }

  &.checkout-page__column--left {
    @include laptop {
      padding-right: rem(20);
      max-width: 55%;
    }
  }

  &.checkout-page__column--right {
    @include laptop {
      padding-left: rem(20);
      max-width: 45%;
    }
  }
}

.checkout-page__basket {
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0.1px;
  background-color: white;
  min-width: 200px;
  z-index: 11;
  animation: slide-left 0.3s ease-out forwards;
  overflow-y: auto;
  padding: 2rem;
  max-height: 100%;
  margin-bottom: rem(20);
  border: none;

  .modal__body {
    height: 100%;
  }

  .basket-item {
    background-color: transparent;
    border-radius: 0;
    justify-content: space-between;
    flex-direction: row;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    .basket-item__top {
      .basket-item__figure {
        width: rem(150);
        height: rem(150);
        overflow: hidden;

        .basket-item__img {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }
      .basket-item__infos__top {
        h2,
        span.txt-s {
          font-family: "Mr Eaves";
          text-transform: uppercase;
          font-size: rem(24);
        }
      }
    }
    .basket-item__bottom {
      .basket-item__bottom--total {
        font-family: "Mr Eaves";
        font-size: rem(24);
        font-weight: 400;
        color: #707070;
      }
    }
  }

  .reacp-text {
    font-style: normal;
    font-family: "Mr Eaves";
    font-size: rem(15);
  }

  .basket-header {
    border-bottom: none;

    h2 {
      font-family: "Mr Eaves";
      font-size: rem(32);
      margin-top: 0;
    }
  }

  .basket-footer__section {
    border-top: none !important;
    text-transform: uppercase;
    .txt-secondary {
      color: black;
    }
  }
}

.checkout-page-left__content-wrapper {
  background-color: $white;
  border-radius: rem(4);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;

  .checkout-page__time-details {
    margin: 0;
    @extend .txt-secondary;
    @extend .txt-m;

    &:last-child {
      @extend .txt-bold;
    }
  }

  &.discount__wrapper {
    margin-top: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: rem(20);

    & > div {
      display: flex;
      flex-direction: column;

      h2 {
        width: 30%;
        font-size: 1.5rem;
        font-family: "Mr Eaves";
      }

      .checkout-form {
        width: 100%;
      }
    }
  }
}

.content-wrapper--padding {
  padding: 0;

  h2.txt-h2 {
    margin-top: 0;
  }
}
