.category-list {
  justify-content: center;
}

.category-item {
  &:hover {
    a {
      animation: fadeInAnimation 0.2s ease-in forwards;
      color: $primary-colour;
    }
  }

  a {
    font-family: "Mr Eaves", sans-serif;
    font-size: rem(30);
  }
}

.navigation__container,
.order-page__container > nav {
  position: sticky;
  top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999;
  background-color: white;

  @include laptop {
    width: 100%;
  }

  left: 0;

  ul.category-list {
    margin-bottom: 0;
    margin-top: 0;
  }
}
