.round {
  background: $primary-colour;
  color: white;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  @include flex(unset, center, center, unset);

  &--up {
    @include font-icon('fas', 'before', '\f077', unset, unset, unset, unset) {
      @extend .txt-xxs;
      @extend .txt-white;
    }
  }

  &--down {
    @include font-icon('fas', 'before', '\f078', unset, unset, unset, unset) {
      @extend .txt-xxs;
      @extend .txt-white;
    }
  }

  &--left {
    @include font-icon('fas', 'before', '\f053', unset, unset, unset, unset) {
      @extend .txt-xxs;
      @extend .txt-white;
    }
  }

  &--right {
    @include font-icon('fas', 'before', '\f054', unset, unset, unset, unset) {
      @extend .txt-xxs;
      @extend .txt-white;
    }
  }
}
