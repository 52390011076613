.unavailable-product-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .unavailable-product-modal__warning {
    margin: auto;
    width: 90px;
    img {
      width: 100%;
    }
  }
  .products-list {
    @extend .txt-center; 
    @extend .txt-bold; 
    @extend .txt-l; 
    @extend .txt-primary-dark;
    p {
      margin: 0;
    }
    ul {
      display: inline-flex;
      padding: 0;
    }
    li {
      &:first-child {
        margin-right: 5px;
      }
      list-style: none;
    }
  }
  .unavailable-product-modal__button {
    margin: auto;
    button {
      @extend .btn-padding;
      @extend .home-main__btn
    }
  }
}
