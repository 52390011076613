// Font ----------------------------------------------------------------
.txt-white {
  color: $white;
}

.txt-black {
  color: $black;
}

.txt-grey-10 {
  color: $grey-10;
}

.txt-grey-20 {
  color: $grey-20;
}

.txt-grey-30 {
  color: $grey-30;
}

.txt-grey-40 {
  color: $grey-40;
}

.txt-grey-50 {
  color: $grey-50;
}

.txt-grey-60 {
  color: $grey-60;
}

.txt-grey-70 {
  color: $grey-70;
}

.txt-grey-80 {
  color: $grey-80;
}

.txt-grey-90 {
  color: $grey-90;
}

.txt-primary {
  color: $primary-colour;
}

.txt-primary-light {
  color: $primary-colour-light;
}

.txt-primary-dark {
  color: $primary-colour-dark;
}

.txt-secondary {
  color: $secondary-colour;
}

.txt-secondary-light {
  color: $secondary-colour-light;
}

.txt-secondary-dark {
  color: $secondary-colour-dark;
}

.txt-tertiary {
  color: $tertiary-colour;
}

.txt-tertiary-light {
  color: $tertiary-colour-light;
}

.txt-tertiary-dark {
  color: $tertiary-colour-dark;
}

.txt-success {
  color: $success-colour;
}

.txt-error {
  color: $error-colour;
}

.txt-warning {
  color: $warning-colour;
}

// Background ----------------------------------------------------------
.bg-transparent {
  background-color: transparent;
}

.bg-white {
  background-color: $white;
}

.bg-black {
  background-color: $black;
}

.bg-grey-10 {
  background-color: $grey-10;
}

.bg-grey-20 {
  background-color: $grey-20;
}

.bg-grey-30 {
  background-color: $grey-30;
}

.bg-grey-40 {
  background-color: $grey-40;
}

.bg-grey-50 {
  background-color: $grey-50;
}

.bg-grey-60 {
  background-color: $grey-60;
}

.bg-grey-70 {
  background-color: $grey-70;
}

.bg-grey-80 {
  background-color: $grey-80;
}

.bg-grey-90 {
  background-color: $grey-90;
}

.bg-primary {
  background-color: $primary-colour;
}

.bg-primary-light {
  background-color: $primary-colour-light;
}

.bg-primary-dark {
  background-color: $primary-colour-dark;
}

.bg-secondary {
  background-color: $secondary-colour;
}

.bg-secondary-light {
  background-color: $secondary-colour-light;
}

.bg-secondary-dark {
  background-color: $secondary-colour-dark;
}

.bg-tertiary {
  background-color: $tertiary-colour;
}

.bg-tertiary-light {
  background-color: $tertiary-colour-light;
}

.bg-tertiary-dark {
  background-color: $tertiary-colour-dark;
}

.bg-success {
  background-color: $success-colour;
}

.bg-error {
  background-color: $error-colour;
}

.bg-warning {
  background-color: $warning-colour;
}

.bg-google {
  background-color: $google-primary-color;
}

.bg-facebook {
  background-color: $facebook-primary-color;
}

// Border --------------------------------------------------------------
.border-transparent {
  border-color: transparent;
}

.border-white {
  border-color: $white;
}

.border-black {
  border-color: $black;
}

.border-grey-10 {
  border-color: $grey-10;
}

.border-grey-20 {
  border-color: $grey-20;
}

.border-grey-30 {
  border-color: $grey-30;
}

.border-grey-40 {
  border-color: $grey-40;
}

.border-grey-50 {
  border-color: $grey-50;
}

.border-grey-60 {
  border-color: $grey-60;
}

.border-grey-70 {
  border-color: $grey-70;
}

.border-grey-80 {
  border-color: $grey-80;
}

.border-grey-90 {
  border-color: $grey-90;
}

.border-primary {
  border-color: $primary-colour;
}

.border-primary-light {
  border-color: $primary-colour-light;
}

.border-primary-dark {
  border-color: $primary-colour-dark;
}

.border-secondary {
  border-color: $secondary-colour;
}

.border-secondary-light {
  border-color: $secondary-colour-light;
}

.border-secondary-dark {
  border-color: $secondary-colour-dark;
}

.border-tertiary {
  border-color: $tertiary-colour;
}

.border-tertiary-light {
  border-color: $tertiary-colour-light;
}

.border-tertiary-dark {
  border-color: $tertiary-colour-dark;
}

.border-success {
  border-color: $success-colour;
}

.border-error {
  border-color: $error-colour;
}

.border-warning {
  border-color: $warning-colour;
}

.border-google {
  border-color: $google-primary-color;
}
