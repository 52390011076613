.change-pickup-time__modal {
  font-size: 1rem;
  text-transform: none;
  padding: 1rem 0;
  width: 100%;
  
  .change-pickup-time__modal__confirm {
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      .change-pickup-time__modal__confirm__btn {
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
  .navigation__menu {
    height: 180px;
  }
}

