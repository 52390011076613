.basket-footer {
  .basket-footer__section {
    border-top: 1px solid $secondary-colour;
    padding: 1rem 0;

    .basket-footer__section--text {
      margin: 0.4rem 0;
    }

    .basket-footer__section--total {
      margin-left: rem(12);
    }

    .basket-footer__button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
