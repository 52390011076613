.filter-item {
  .filter-item__button {
    padding: 0.4rem 1.1rem 0.4rem;
    background-color: $tertiary-colour-light;
    font-family: "Mr Eaves", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    &.active {
      background-color: $primary-colour;
      color: $secondary-colour-light;
    }
  }
}
