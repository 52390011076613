.filters-container {
  animation: fadeInAnimation ease-in-out 1s;
  width: 100%;
  @include tablet {
    width: 60%;
  }
  @include desktop {
    width: 70%;
  }
}

.filter-list {
  @include flex(row, flex-start, center, unset);
  padding: 0 0 1rem 1.2rem;
  overflow-x: auto;
  max-width: 100vw;
  margin-bottom: 0;
  @include tablet {
    width: auto;
    overflow-y: auto;
    margin: 0 0 0 1rem;
    padding: 0 0 0.4rem 0;
  }
}
