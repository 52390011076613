.payment-modal {
  .payment-modal__check {
    @include flex(unset, center, center, center);
  }

  .payment-modal__actions {
    margin: rem(20) auto;
    @include flex(column, unset, center, unset);
  }

  .payment-modal__btn {
    margin-top: rem(16);
    margin-left: auto;
    margin-right: auto;
    padding-top: rem(16);

    &--cancel {
      background-color: $secondary-colour;
      border: 1px solid $secondary-colour;

      &:hover {
        background-color: $secondary-colour-dark;
      }
    }
  }
}
