.filter-item {
  list-style: none;
  margin-right: .6rem;

  @include tablet {
    margin: 0.6rem 0.6rem 0.6rem 0;
  }

  .filter-item__button {
    border: none;
    background-color: $secondary-colour-light;
    color: $secondary-colour-dark;
    padding: 0.6rem 1.1rem 0.4rem;
    border-radius: 40px;
    transition: 0.2s;
    white-space: nowrap;
    cursor: pointer;

    &.active {
      background-color: $secondary-colour-dark;
      color: $secondary-colour-light;
    }

    @include tablet {
      &:hover {
        background-color: $secondary-colour;
        color: $secondary-colour-light;
        cursor: pointer;
        transition: 0.2s;
      }
    }
  }
}
