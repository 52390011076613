.popover {
  &.popover__active--RIGHT,
  &.popover__inactive--RIGHT {
    .popover__background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.8);
      z-index: 10;
    }

    .popover__box {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 240px;
      ///////
      background-color: $tertiary-colour-light;
      ///////
      z-index: 11;
      padding: rem(24);
      overflow-y: auto;
      max-height: 100%;

      .popover__close {
        @extend .close;
      }

      .popover__body {
        height: 100%;

        .basket-item {
          box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0.1px;

          .basket-item__bottom--quantity > .txt-secondary-dark {
            display: none;
          }

          .basket-item__top .basket-item__infos {
            padding-bottom: 0;
          }

          .basket-item__bottom {
            padding-top: 0;
            flex-direction: row;
            align-items: center;

            .basket-item__bottom--total {
              font-family: "Mr Eaves", sans-serif;
              font-weight: 700;
              font-size: rem(20);
            }
          }
        }
      }
    }
  }

  &.popover__active--RIGHT {
    .popover__background {
      animation: fade-overlay 0.3s ease-out forwards;
    }

    .popover__box {
      animation: slide-left 0.3s ease-out forwards;
    }
  }

  &.popover__inactive--RIGHT {
    .popover__background {
      animation: fade-overlay-out 0.3s ease-out forwards;
    }

    .popover__box {
      animation: slide-left-out 0.3s ease-out forwards;
    }
  }
}
