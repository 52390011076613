.restaurant-authentication {
  background-color: $tertiary-colour-dark;
}

.restaurant-authentication-content {
  width: 80%;
  margin: auto;

  .restaurant-authentication-content__logo {
    margin: auto;
    @include mobile {
      width: 22rem;
    }
    img {
      width: 100%;
    }
  }

  .restaurant-authentication-content__title {
    font-size: 8.4vw;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    text-align: justify;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    @include mobile {
      font-size: 2.3rem;
    }
  }

  .restaurant-authentication-error {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
      @extend .txt-error;
    }
  }

  .restaurant-authentication-link {
    margin-top: 1rem;
    display: flex;
    a {
      @extend .txt-underline;
      margin: auto;
    }
  }

  .form {
    margin: auto;
  }

  .form .form__btn {
    margin: 1rem auto;
    max-width: 24rem;
  }
}