.order-success {
  background-color: $white;
  height: 100%;
  display: flex;
  padding-bottom: 0.4rem;
  border-radius: 0;
  box-shadow: none;

  .order-success__time {
    height: 30px;
    width: 30px;
    margin: 0.5rem 1rem;

    svg {
      fill: $secondary-colour;
    }
  }

  .txt-error {
    margin: 0;
    align-self: center;
  }
}
