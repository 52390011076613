.checkout-form {
  &:first-child {
    border-top-right-radius: rem(4);
    border-top-left-radius: rem(4);
  }

  &.checkout__info {
    margin-top: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: rem(20);

    & > div {
      display: flex;
      flex-direction: column;

      h2 {
        width: 30%;
        font-size: 1.5rem;
        font-family: "Mr Eaves";
      }

      .checkout-form {
        width: 100%;
      }
    }

    .form-input__container {
      padding: 0;
      margin-bottom: rem(20);

      label.form-input__label {
        width: 30%;
        font-family: "Mr Eaves", sans-serif;
        font-size: rem(20);
        font-weight: 400;
        text-align: right;
        padding-right: 0;
      }

      input.form-input__input {
        border: 3px solid $secondary-colour-light;
        flex: 1;
        padding: 1rem;
        margin-left: rem(10);

        &:focus {
          outline: none;
          background-color: white;
        }
      }
    }
  }
}

.form-input__container {
  background-color: $white;
  padding: 0.5rem 1rem;
}

.form-input__container--input {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:first-child {
    border-top-right-radius: rem(4);
    border-top-left-radius: rem(4);
    padding: 1rem;
  }
}

.form-input__container--stripe {
  background-color: #fff;
  padding: rem(15);
}

.form-input__action {
  display: flex;
  flex-direction: column;
  padding: 0 2rem 1rem;
  margin: auto;
  width: 70%;

  .btn__primary {
    margin: 0 auto;
    width: 100%;
    max-width: none;
  }
}

.form-input__container--error {
  padding: 0 1rem;
  margin: 0;
}

.form-input__label {
  padding: 0 rem(15) 0 0;
  width: 90px;
  @extend .txt-s;
  @extend .txt-bold;
  @extend .txt-secondary;
}

.form-input__border {
  border: 3px solid $secondary-colour-light;
  flex: 1;
  padding: 1rem;

  &:focus {
    outline: none;
    background-color: white;
  }
}

.form-input__input {
  border-width: 0px;
  border: none;
  flex: 1;
  padding: 1rem;
  border-bottom: 1px solid black;

  &:focus {
    outline: none;
    background-color: white;
  }

  &::placeholder {
    color: $secondary-colour;
    opacity: 0.7;
  }
}

.cards-container {
  padding: 0px 10px 10px 10px;
  vertical-align: baseline;
  user-select: none;

  .checkbox-container {
    margin: 0.5rem 0.5rem 1rem 0.5rem;

    .payment-checkbox {
      margin-right: 1rem;
    }

    .form-input__label {
      line-height: 2;
      cursor: pointer;
      font-family: "Mr Eaves", sans-serif;
      font-size: rem(20);
    }
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    vertical-align: middle;
    margin: 0;
    height: 20px;
    width: 20px;
    outline: none;
    border: 2px solid $primary-colour;
    cursor: pointer;

    &.checked {
      box-shadow: 0 0 0px 2px $white inset;
      background: $primary-colour;
    }
  }

  input[type="checkbox"]:before {
    content: "";
    display: block;
  }
}

#indexForm {
  display: none;
}

.payment-method {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: 1px solid $grey-30;
  background-color: $white;
  position: relative;
  padding: 1rem;

  @include mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.checked {
    border: 1px solid $primary-colour;
    box-shadow: 0px 0px 3px 0 $primary-colour;
  }

  .payment-method__container {
    @include flex(unset, flex-start, flex-start, nowrap);
  }

  .payment-method__top {
    display: flex;
    justify-content: flex-end;
    @extend .basket-item__top;
    margin: 0;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.payment-method__figure {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  margin: 0.3rem;

  @include mobile {
    width: 55px;
    height: 55px;
    margin: auto 0.8rem;
  }
}

.payment-method__img {
  width: 100%;
  height: auto;
  @include middle-image;
}

.payment-method__content {
  padding: 0.5rem;
  flex-grow: 1;
  position: relative;

  @include mobile {
    padding: 0.4rem 0rem;
  }

  .payment-method__name {
    @extend .txt-secondary-dark;

    @include tablet {
      max-width: 80%;
    }
  }

  input[type="radio"] {
    -webkit-appearance: none;
    width: 22px;
    height: 22px;
    outline: none;
    border: 2px solid $primary-colour;
    vertical-align: middle;
    margin: 0 0 0 0.3rem;
    box-shadow: 0 0 0px 2px $white inset;

    &.checked {
      box-shadow: 0 0 0px 2px $white inset;
      background: $primary-colour;
    }
  }

  input[type="radio"]:before {
    content: "";
    display: block;
  }

  input[type="radio"]:disabled {
    box-shadow: 0 0 0px 2px $white inset;
    background: $white;
    border: 2px solid $grey-20;
    cursor: default;
  }

  @include tablet {
    @include flex(column, unset, unset, unset);
  }
}

.payment-method__description {
  margin: 0;

  @include tablet {
    flex-grow: 1;
  }
}
