.restaurant-item {
  background-color: white;

  &--active {
    background-color: $tertiary-colour-light;
  }

  .restaurant-item__tag {
    @include absolute(unset, rem(40), unset, unset);
    padding: 0.7rem 1rem 0.5rem;
    font-family: "Mr Eaves", sans-serif;
  }
}

.restaurant-item__actions {
  flex-direction: column;

  .restaurant-item__pick-button {
    display: block;
    width: 100%;
    background-color: $primary-colour;
    color: white;
    border-radius: 0;
    border: none;
    font-size: rem(20);
  }

  a {
    border: 1px solid #00ccbc;
    width: 100%;
    text-align: center;
    height: rem(42);
    padding: rem(5);
    margin-top: rem(10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .brand-logo {
      overflow: hidden;
      svg {
        height: rem(28);
      }
    }

    &.justeat {
      border: 1px solid #ff8000;
    }

    &.ubereats {
      border: 1px solid #5fb709;

      svg {
        height: rem(20);
      }
    }
  }

  button.restaurant-item__see-more {
    padding-right: 0;
    justify-content: center;
  }
}

.restaurant-item__details {
  p.restaurant-item__hours-list-day {
    width: 50%;
  }
  p.restaurant-item__hours-list-time {
    width: 50%;
  }
  p.restaurant-item__hours-list-day,
  p.restaurant-item__hours-list-time {
    font-family: "Mr Eaves", sans-serif;
    font-weight: 400;
    font-size: rem(18);
  }
  .restaurant-item__hours,
  .restaurant-item__infos {
    padding-top: rem(5);
    padding-bottom: rem(15);

    p {
      font-family: "Mr Eaves", sans-serif;
      font-weight: 400;
      font-size: rem(18);

      &.txt-bold {
        font-weight: 700;
      }
    }
  }
}

.restaurant-item__container {
  .restaurant-item__main-info {
    position: relative;

    &:before {
      position: absolute;
      background-image: url("../../../../../../src/assets/images/lce-small.png");
      background-size: cover;
      display: block;
      width: 35px;
      height: 46px;
      content: "";
      top: -15px;
      left: -35px;
    }
    p {
      font-family: "Mr Eaves", sans-serif;
      font-weight: 400;
      font-size: rem(18);
      display: flex;
      justify-content: space-between;

      &.txt-bold {
        font-weight: 700;
      }

      span.distance {
        font-family: "Mr Eaves", sans-serif;
        margin-left: auto;
        font-weight: 400;
      }
    }
  }
}
