.footer {
  background-color: $tertiary-colour-light;
  padding-top: 10px;

  @include tablet {
    height: auto;
  }

  .footer-block__left {
    .footer__button {
      color: $secondary-colour-dark;
      text-transform: none;
      &:hover {
        color: $primary-colour;
      }
    }
  }
  .footer-block__center {
    ul.footer__links {
      list-style-type: none;
      text-align: center;

      li {
        display: inline-block;
        position: relative;
        padding-right: rem(5);
        margin-right: rem(5);

        &:last-of-type {
          padding-right: 0;
          border-right: none;

          a::after {
            display: none;
          }
        }

        &:hover {
          color: black;

          a {
            color: black;
          }
        }

        a {
          font-weight: 900;
          font-family: "Mr Eaves";
          font-size: rem(20);

          &::after {
            content: "I";
            position: absolute;
            top: 0;
            right: rem(-2);
          }
        }
      }
    }
    p {
      font-family: "Mr Eaves";
      font-size: rem(20);

      a {
        font-family: "Mr Eaves";
        font-size: rem(20);
      }
    }
    p,
    a {
      color: $secondary-colour-dark;
    }
    a:hover {
      color: $primary-colour;
    }
  }

  .social__links {
    width: calc(100% - 40px);
    border-bottom: solid 1px $primary-colour;
    border-top: solid 1px $primary-colour;
    padding: 0;
    margin: 0 20px;
    text-align: center;

    @include laptop {
      text-align: left;
    }

    a {
      width: 40px;
      height: 40px;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 8px;

      svg {
        width: 100%;
      }
    }
  }
}
