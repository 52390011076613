// CategoryNavigationDesktop.tsx
.category-list {
  margin-top: 2rem;
  list-style: none;
  @include flex(unset, unset, unset, wrap);
  flex: 1;
  padding-left: 0;
}

// CategoryItem.tsx
.category-item {
  margin: .5rem 0;
  padding: 0 1rem 0;
  border-right: 1px solid $tertiary-colour-dark;
  color: $secondary-colour-dark;

  // We have to keep it - comes from react <Link/> component
  a {
    text-decoration: none;
    color: $secondary-colour;
    text-transform: uppercase;
    font-weight: bold;

    &:active {
      color: $secondary-colour-dark
    }

    &:hover {
      color: $secondary-colour-light
    }

  }

  .current {
    color: $primary-colour;
  }
}

li.category-item {
  &:last-child {
    border-right: none;
  }
}

//CategoryNavigationMobile.tsx
.navigation__container {
  padding: 0 0.75rem;
  margin: 2rem auto 1rem;
}
