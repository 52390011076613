html {
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: $theme-bg;
  scroll-behavior: smooth;
}

#root {
  margin: 0;
  background-color: $theme-bg;
}

main {
  display: flex;
  padding-top: 50px;
}

.special-footer {
  main {
    min-height: calc(100% - 218px); // remove header & footer height

    @include tablet {
      min-height: calc(100% - 200px);
    }
  }
}

p {
  color: $secondary-colour;
}

a {
  color: $secondary-colour-dark;
  @extend .txt-bold;
  @extend .txt-underline;
}

// It's mandatory due the font-family
p,
h1,
h2,
h3,
h4,
span,
a {
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}
