.header-product__list {
  margin-top: 10px;
}

.product-list {
  margin: 1rem;
  @include grid(auto, repeat(1, 1fr), rem(26));

  @include tablet {
    @include grid(auto, repeat(2, 1fr), rem(26));
  }

  @include desktop {
    @include grid(auto, repeat(4, 1fr), rem(26));
  }

  @include smallscreen {
    @include grid(auto, repeat(1, 1fr), rem(26));
  }
}

.product-item {
  .product-item__button {
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0.1px;
    .product-item--title {
      @extend .txt-uppercase;
    }
    .product-item__image {
      object-fit: cover;
    }
    &:hover {
      .product-item__image {
        transform: none !important;
      }
    }

    &.custom__pizza {
      .product-item__image-container {
        height: 150px;
      }
      .product-item--infos {
        display: flex;
        flex-direction: column;
        align-items: center;

        .custom__pizza__title {
          margin: 0 auto;
          font-weight: 700;
          font-size: rem(30);
          color: black;
          font-family: "Mr Eaves", sans-serif;
          text-transform: uppercase;
          display: block;
          text-align: center;
        }
        .product-item--title {
          margin: 0;
          color: black;
          font-family: "LCScript", sans-serif;
          font-size: rem(30);
          text-align: left;
          padding-left: 0;
          margin-top: rem(10);
          margin-bottom: 0;
          line-height: 1;
        }
        .product-item--price.add-to-cart {
          margin: rem(10) auto;
          border: 1px solid $primary-colour;
          color: $primary-colour;
          font-family: "Mr Eaves", sans-serif;
          font-weight: 400;
          padding: rem(5) rem(20);
          font-size: rem(20);
          text-transform: uppercase;
        }
      }
    }
  }
  .product-item__image {
    background-color: white;
  }
  .product-item__image-container {
    background-color: $tertiary-colour-light;
  }
  .product-item--infos {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: start;
    .product-item--title {
      margin: 0;
      color: black;
      font-family: "Mr Eaves", sans-serif;
      font-size: rem(30);
      text-align: left;
      padding-left: 5px;
    }
  }
  .product-item--price {
    background-color: $secondary-colour;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    top: 0;
    right: 0;
    padding-top: 0;
    background-color: transparent;
    font-family: "Mr Eaves", sans-serif;
    color: black;
    span {
      font-weight: 700;
      font-size: rem(20);
      color: black;
      font-family: "Mr Eaves", sans-serif;
    }

    &.add-to-cart {
      padding: 0;
      border: none;
      display: flex;
      cursor: pointer;

      svg {
        width: rem(30);
        height: rem(30);
        border: 1.5px solid rgb(180, 173, 170);
        border-radius: 6px;
      }
    }

    &.ml-auto {
      margin-left: auto;
    }

    &--disabled.add-to-cart {
      cursor: none;
      pointer-events: none;

      svg {
        width: rem(30);
        height: rem(30);
        border: 1.5px solid rgb(180, 173, 170);
        border-radius: 6px;
        background-color: rgb(180, 173, 170);

        line {
          stroke: white;
        }
      }
    }
  }

  .product-item__choice-button {
    @extend .product-item__button;
    @include flex(column, center, center, unset);
    min-height: rem(279);
    opacity: 1;
    animation: fade-in 0.2s ease-out forwards;
    background-color: transparent;
    z-index: 2;
    .choice-button {
      flex: 1;
      width: 100%;
      background-color: transparent;
      border: none;
      color: $tertiary-colour-light;
      @include flex(column, center, center, unset);
      &:hover {
        cursor: pointer;
        color: $tertiary-colour-light;
        .button-icon {
          &.button-icon--burger,
          &.button-icon--meal {
            fill: $primary-colour-light;
          }
        }
      }
      .button-title {
        padding-top: 0;
        @extend .txt-l;
        margin: 0 1.5rem;
        font-family: "Mr Eaves", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: rem(22);
      }
      &.choice-button--top {
        border-bottom: 1px solid $tertiary-colour-light;
      }
      &.choice-button--bottom {
        border-bottom: none;
      }
      .button-icon {
        &.button-icon--arrow {
          stroke: $tertiary-colour-light;
          width: 20px;
        }
        &.button-icon--burger {
          fill: $tertiary-colour-light;
          width: 60px;
        }
        &.button-icon--meal {
          fill: $tertiary-colour-light;
          width: 50px;
        }
      }
    }
  }
}

.hover-parent {
  .hover-child {
    z-index: 2;
    background-color: rgba(255, 103, 27, 0.85);
  }
}
