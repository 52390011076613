.add-cart {
  @include flex(column, unset, center, unset);
  height: 46px;
  width: 176px;

  .add-cart__button {
    font-size: rem(16);
    padding: rem(14) rem(15) rem(10);

    &--disabled {
      cursor: initial;
      border: $primary-button-border-disabled;
      color: $primary-button-txt-disabled;
      background-color: $primary-button-background-disabled;
      opacity: $primary-button-opacity-disabled;
      border: none;
      padding: rem(14) rem(15) rem(10);
      font-size: rem(16);

      &::before,
      &::after {
        color: $grey-40;
      }
    }
  }

  .add-cart__warning {
    box-shadow: 0 10px 8px -8px rgb(0 0 0 / 25%);
    padding: rem(10) rem(10) rem(7);
    border-radius: 7px;
    position: absolute;
    top: rem(-30);
    background-color: $primary-colour;

    &::after {
      content: "\a";
      border-style: solid;
      border-width: 13px 10px 13px 0;
      border-color: transparent $primary-colour transparent transparent;
      position: absolute;
      bottom: rem(-16);
      left: 50%;
      transform: rotate(-90deg);
      transform-origin: center;
    }
  }
}
