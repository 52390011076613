.restaurant-item {
  padding: 1.25rem;
  border-top: solid rem(.1) $tertiary-colour-dark;
  position: relative;
  background-color: $tertiary-colour;

  .restaurant-item__container {
    position: relative;
  }

  &--active {
    background-color: $tertiary-colour-dark;
  }

  .restaurant-item__tag {
    @include absolute(unset, 0, unset, unset);
    padding: .7rem 1rem .5rem;
  }

  // .restaurant-item__main-info {
  //   cursor: pointer;
  // }

  .restaurant-item__see-more {
    border: none;
    border-radius: 0;
    color: $primary-colour-dark;
    transition: .5s;
    padding-left: 0;
    width: initial;
    min-width: 140px;
    align-items: center;
    @include flex(row, unset, unset, nowrap);

    &:hover {
      background-color: transparent;
      color: $primary-colour;

      &.btn__secondary--mini-inverse::after {
        color: inherit;
      }
    }

    &.btn__secondary--mini-inverse::after {
      color: inherit;
    }

    &--open {
      &::after {
        display: inline-block;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }
    }
  }

  .restaurant-item__actions {
    @include flex(unset, space-between, center, nowrap);
  }

  .restaurant-item__details {
    .border-bottom {
      border-bottom: solid rem(.1) $grey-10;
    }
  }

  .restaurant-item__address {
    border-bottom: solid rem(.1) $grey-10;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .restaurant-item__hours {
    padding: unset;
    padding-top: 1.25rem;
    padding-bottom: 2.5rem;

  }

  .restaurant-item__hours-list {
    @include flex(unset, unset, unset, unset);

  }

  .restaurant-item__hours-list-day {
    margin: unset;
    display: inline-block;
    width: rem(150);
  }

  .restaurant-item__hours-list-time {
    display: inline-block;
    margin: unset;
  }

  .restaurant-item__infos {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

}

.restaurant-item__pick-button {
  @extend .txt-extrabold;
  min-width: 100px;

  &--active {
    // @extend .restaurant-item__pick-restaurant;
    border: 1px solid $primary-colour;
    background-color: $primary-colour;

    &.btn__secondary--icon-after {
      &::after {
        color: $tertiary-colour;
        @extend .txt-secondary
      }
    }

    &:hover {
      background-color: $primary-colour;
    }
  }
}
