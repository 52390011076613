.current-order-main {
  background-color: $secondary-colour-light;
  .current-order {
    padding: 0 0.8rem;
    margin: 0 auto 1.5rem;

    width: 100%;

    @include tablet {
      max-width: #{$tabletContainer}#{$unit};
      width: 100%;
    }

    @include laptop {
      max-width: #{$laptopContainer}#{$unit};
    }

    @include desktop {
      max-width: #{$desktopContainer}#{$unit};
    }

    .current-order__cancel-title {
      // margin: 2.2rem auto 2.5rem auto;
      // display: block;
      justify-content: center;
      text-align: center;
    }

    .current-order__div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem auto;
    }

    .current-order__actions {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      @include tablet {
        flex-direction: row;
      }

      .btn__order-again {
        margin: 10px auto 10px auto;
        color: white;
        @include tablet {
          margin: 10px auto 10px 0px;
          float: left;
        }
      }

      @media (hover: none) {
        .see__more-bckg__black {
          background: black;
          color: white;
        }

        .see__more-bckg__white {
          background: white;
          color: black;
        }
      }

      @media (hover: hover) {
        .see__more-bckg__black {
          background: black;
          color: white;
        }
      }
    }

    .current-order__button {
      margin-top: 12px;
    }

    .current-order__text {
      margin-bottom: 2rem;
      padding: 0;
    }

    .current-order__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include tablet {
        flex-direction: row;
      }
    }

    .current-order__loading {
      height: 60%;
    }

    .order-btn__action {
      min-width: 210px;
    }

    .current-order__see__more {
      @extend .btn__secondary--inverse;
      margin: 10px auto 10px auto;
      width: auto;

      @include tablet {
        float: right;
        margin: 10px 0px 10px auto;
      }
    }

    .current-order__cancel__button {
      @include tablet {
        float: left;
        margin-left: 15px;
      }
    }
  }
}
