.order-waiting {
  @include absolute(0, unset, unset, 0);
  @include flex(row, center, center, unset);
  height: 100%;
  width: 100%;
  background-color: $secondary-colour-dark;
  opacity: .5;
  z-index: 1;
  animation: fadeInAnimationTransparency .2s ease-out forwards;
}
