.profile-modal {
  text-align: center;

  .profile-modal__warning {
    width: 90px;
    margin: auto;
    img {
      width: 100%;
    }
  }

  .profile-modal__icon {
    margin: rem(20) auto;
    width: 4.8rem;
    height: 4.8rem;
    background-color: $success-colour;
    border-radius: 50%;
    @include flex(unset, center, center, unset);

    @include font-icon("fas", "before", "\f00c", unset, unset, unset, unset) {
      @extend .txt-l;
      @extend .txt-white;
    }
  }

  .profile-modal__icon--error {
    margin: rem(20) auto;
    width: 4.8rem;
    height: 4.8rem;
    background-color: $error-colour;
    border-radius: 50%;
    @include flex(unset, center, center, unset);

    @include font-icon("fas", "before", "\f00d", unset, unset, unset, unset) {
      @extend .txt-white;
      font-size: rem(42);
    }
  }

  .profile-modal__button {
    margin: rem(10) auto rem(20);

    @include desktop {
      width: 40%;
      margin: rem(10) rem(10) rem(20);
    }
  }


}
