.search-engine-wrapper {
  width: 100%;

  &--list {
    
      @include tablet {
        display: none;
        width: 40%;
      }
    
      @include desktop {
        width: 30%;
      }
  }

  &--navbar {
    .search-engine {
      margin: 0 !important;
    }
  }

  .search-engine {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 1.2rem 0.4rem;

    .search-clear__icon {
      position: absolute;
      right: 10px;
      width: 16px;
      height: 16px;
      border-radius: 45px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(45deg) scale(0.8);
      transition-duration: 0.2s;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        width: 60%;
        height: 2px;
        background-color: white;
      }

      &::after {
        content: "";
        position: absolute;
        width: 60%;
        height: 2px;
        background-color: white;
        transform: rotate(90deg);
      }

      &:hover {
        transform: rotate(45deg) scale(1);
        opacity: 0.7;
      }
    }

    .search-icon {
      position: absolute;
      left: 10px;
      color: white;
      width: 22px;
      height: 22px;

      svg {
        fill: $primary-colour;
      }
    }

    .search-input {
      padding: 12px 0px 12px 0px;
      padding-left: 45px;
      width: 100%;
      height: 100%;
      outline: 0;
      border: 1px solid transparent;
      box-shadow: 0px 0px 2px 1px rgba(212, 212, 212, 0.75);

      &:focus {
        border: 1px solid black !important;
      }
    }

    /* Search suggestions */
    .search-suggestions__content {
      position: absolute;
      top: 100%;
      margin-top: 5px;
      width: 100%;
      max-height: 280px;
      overflow-y: scroll;
      background-color: $white;
      font-weight: bold;
      z-index: 3;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary-colour;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .suggestion-product__tile--unavailable {
        opacity: 0.3;
        pointer-events: none;
      }

      .suggestion-product__tile {
        margin: 1px auto;
        width: 90%;
        height: 45px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e3e3e3;
        cursor: pointer;

        &:hover {
          color: $primary-colour;
        }

        .arrow__icon {
          position: absolute;
          right: 20px;
          width: 16px;
          height: 16px;
          transform: rotate(270deg);
        }
      }
    }
  }
}
