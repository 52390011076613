.basket-updated {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include font-icon('fas', 'before', '\f071', unset, unset, unset, unset) {
    @extend .txt-primary-dark;
    font-size: rem(50);
    margin: 1rem 0;

    @include tablet {
      margin-bottom: 2rem;
      font-size: 3rem;
    }
  }

  .basket-updated__close {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @include font-icon('fas', 'before', '\f00d', unset, unset, unset, unset) {
      @extend .txt-xs;
      @extend .txt-black;
    }
  }

  @include font-icon('fas', 'before', '\f071', unset, unset, unset, unset) {
    @extend .txt-xl;
    @extend .txt-secondary-dark;
    margin: 1rem 0;

    @include tablet {
      margin-bottom: 2rem;
      @include font-size-xxl;
    }
  }

  .basket-updated__text {
    text-align: center;
    font-weight: 600;
    margin-bottom: 0;
    @include laptop {
      margin-right: 4rem;
      margin-left: 4rem;
    }
  }

  .basket-updated__conflicts-list {
    padding: 0;
  }

  .basket-updated__btn {
    margin: rem(30) auto rem(20);
  }

  .basket-updated__list {
    padding: 0;
    @include flex(column, center, center, unset);
  }

  .basket-updated__list-item {
    color: $secondary-colour;
  }
}
