.navbar {
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px 0.1px;
  background-color: $primary-colour;
  &-disabled {
    background-color: black;
    height: 50px;

    .navbar__container--title {
      font-family: "Mr Eaves";
      @extend .txt-bold;
      font-size: rem(30);
    }
  }
  .navbar__block--left {
    display: none;

    @include tablet {
      // visibility: hidden;
      display: flex;
      height: rem(30);
    }
  }
  .navbar__block--center {
    height: 50px;
    .navbar__block--home-button {
      height: 100%;
    }
  }
  .navbar__button {
    // Change timer icon and basket icon color
    &--basket {
      fill: $secondary-colour;
    }
    &::before {
      color: $secondary-colour;
    }

    p.current-restaurant__paragraph--text {
      color: white;
      text-transform: uppercase;
      font-family: "Mr Eaves", sans-serif;
      font-size: rem(15);
      border-bottom: 1px solid white;

      @include tablet {
        font-size: rem(15);
      }

      @include desktop {
        font-size: rem(20);
      }
    }
    // &::after {
    //   background: $primary-colour;
    //   @extend .txt-primary;
    // }
    padding: 7px;

    @include tablet {
      padding: 15px;
    }

    &--basket {
      height: auto;
      width: 30px;
      position: relative;
      fill: $primary-colour;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .navbar__block--right {
    .notification[data-notification]::after {
      background: $primary-colour;
    }

    flex-basis: 70%;

    @include tablet {
      flex-basis: 30%;
    }
  }
}
