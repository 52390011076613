// from pages --------------
.home-main__warning {
  max-width: 300px;
  margin: rem(50) auto;
}

.home-main__actions {
  margin: rem(30) auto;
  width: fit-content;
  text-align: center;
  @include flex(unset, center, center, wrap);
}

.home-main__btn {
  margin: rem(10);
}

.home-main__actions--full {
  @include flex(column, center, center, unset);
}

.home-main__btn--full {
  margin: rem(10) auto;
}

// From layouts -----------
.header {
  width: 100%;
  padding: 2rem 0;
  text-align: center;
}

// From components -------
.warning {
  padding: 1rem 2rem;
  border-radius: $themeBorderRadius;
}

// Loading div -------
.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loading-parts {
  display: flex;
  justify-content: center;
  align-items: center;
}
