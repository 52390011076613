.navbar {
  background-color: $tertiary-colour-light;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .navbar__container {
    @include flex(unset, space-between, center, nowrap);
    padding: 0 1rem;
  }

  .navbar__block {
    flex-basis: 30%;
    height: 50px;
    @include flex(row, center, center, nowrap);

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;

      .navbar__block--home-button {
        @include flex(row, center, center, unset);
        height: 2.8rem;
        width: auto;

        img {
          height: 90%;

          @include tablet {
            height: 100%;
          }
        }
      }
    }

    &--right {
      justify-content: flex-end;
      align-items: center;

      .notification {
        &[data-notification]::after {
          right: 55%;
          width: 15px;
          height: 15px;
          background: $primary-colour-dark;

          @include tablet {
            right: 62%;
            width: 17px;
            height: 17px;
          }
        }
      }

      .navbar__button {
        padding: 7px;

        @include tablet {
          padding: 15px;
        }
      }
    }
  }

  .navbar__button {
    border: none;
    background-color: transparent;
    border-radius: $themeBorderRadius;
    cursor: pointer;
    height: 100%;
    padding: 0;
    @include flex(unset, center, center, nowrap);

    @include tablet {
    }

    &::before {
      @extend .txt-xl;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    &.navbar__button--active {
      background-color: $primary-colour;
      border-radius: 0;

      &::before {
        color: $white;
      }

      &::after {
        background: $secondary-colour;
      }
    }

    &--menu {
      @include font-icon("fas", "before", "\f0c9", unset, unset, unset, unset) {
        @extend .txt-xl;
        @extend .txt-secondary;
      }

      font-weight: bold;

      @include laptop {
        padding: rem(5) rem(10);
        width: initial;
        height: initial;
      }
    }

    &--timer {
      @include font-icon("fas", "before", "\f2f2", unset, unset, unset, unset) {
        @extend .txt-xl;
        @extend .txt-primary;
      }
    }

    &--restaurant {
      width: auto;
      height: 1.1rem;

      @include mobile {
        height: 1.8rem;
      }
    }

    &--basket {
      height: 1.1rem;
      width: auto;
      position: relative;
      fill: $primary-colour;
    }
  }

  .navbar__button-text {
    display: none;

    @include laptop {
      display: block;
      margin-left: rem(10);
    }
  }
}

.navbar-disabled {
  @include flex(unset, center, center, nowrap);
  background-color: $secondary-colour-dark;
  height: rem(50);

  .navbar__container--title {
    margin: auto;
  }
}
