.radio-button {
  margin: 0 1rem 0;
  @extend .bg-transparent;
  height: rem(20);
  width: rem(20);
  border: 2px solid $primary-colour;
  border-radius: 100%;
  cursor: pointer;
  padding: 0;
  pointer-events: auto;

  @include tablet {
    margin: 0 2rem 0;
  }

  &.active {
    background-color: $primary-colour;
    box-shadow: 0 0 0 2px $white inset;
    transition: 0.3s;
  }

  &:focus {
    outline: none;
  }
}
