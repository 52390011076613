.add-cart {
  .add-cart__button {
    padding: rem(14) rem(15);
    color: $tertiary-colour-light;

    &::before {
      vertical-align: bottom;
    }
  }
}
