.profile {
  width: 90%;
  padding-bottom: 30px;

  .search {
    padding: 0;
  }
  .bg-secondary-dark {
    background-color: transparent;
  }

  @include flex(column, center, center, unset);

  @include tablet {
    width: 50%;
  }

  @include laptop {
    width: 40%;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    @extend .txt-primary;
    margin-bottom: .4rem;
  }

  &__reset {
    margin-top: 0;
  }

  .profile__valid {
    width: 4.8rem;
    height: 4.8rem;
    background-color: $primary-colour;
    border-radius: 50%;
    margin: 1rem 0;
    @include flex(unset, center, center, unset);

    @include font-icon("fas", "before", "\f00c", unset, unset, unset, unset) {
      @extend .txt-xxl;
      @extend .txt-white;
    }
  }

  &__not-valid {
    width: 4.8rem;
    height: 4.8rem;
    background-color: $error-colour;
    border-radius: 50%;
    margin: 1rem 0;
    @include flex(unset, center, center, unset);

    @include font-icon("fas", "before", "\f00d", unset, unset, unset, unset) {
      @extend .txt-xxl;
      @extend .txt-white;
    }
  }

  &__paragraph {
    text-align: center;
  }

  &__link {
    text-align: center;

    p {
      @extend .txt-s;
      text-decoration: underline;
    }
  }

  &__account {
    text-align: center;
    margin: 0 auto;

    &__login {
      font-weight: 600;
      padding-left: 0.3rem;
    }
  }

  .profile__guest {
    margin: 1rem 2rem 2rem;
    line-height: 1.5rem;
    @include flex(column, center, center, unset);

    @include tablet {
      margin: 1rem 0 2rem 0;
    }

    @extend .profile__account;

    &__login {
      @extend .profile__account__login;
    }
  }

  .form {
    width: 100%;
  }

  &.profile--error {
    margin: 1rem auto;
    text-align: center;
  }
}
