.modal__order-disabled {
  .modal__close {
    display: none;
  }
}

.order-disabled {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .order-disabled__close {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @include font-icon("fas", "before", "\f00d", unset, unset, unset, unset) {
      @extend .txt-xs;
      @extend .txt-black;
    }
  }

  @include font-icon("fas", "before", "\f071", unset, unset, unset, unset) {
    @extend .txt-xl;
    @extend .txt-secondary-dark;
    margin: 1rem 0;

    @include tablet {
      margin-bottom: 2rem;
      @include font-size-xxl;
    }
  }

  .order-disabled__text {
    text-align: center;
    font-weight: 600;
  }

  .order-disabled__actions {
    margin: rem(20) auto;
    width: 100%;
    @include flex(column, center, center, unset);

    @include tablet {
      flex-direction: row;
      max-width: 520px;
    }
  }

  .order-disabled__btn {
    &.btn__secondary--inverse {
      padding: rem(13) rem(27);
      font-size: rem(14);
    }

    &--continue {
      margin-top: rem(20);

      @include tablet {
        margin-top: 0;
        margin-left: rem(20);
      }
    }
  }
}
