.order-container {
  background-color: $white;
  height: 100%;
  margin-bottom: 1rem;
  padding: 0.8rem;
  border-radius: 0;
  box-shadow: none;
  position: relative;

  @include tablet {
    flex: 1;
  }

  .order-container__rating--modal {
    display: flex;
    justify-content: center;
  }

  .order-container__title {
    display: flex;
    flex-direction: row;
    border-bottom: solid 0.14rem $grey-10;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.6rem 1rem 0.6rem;

    &--left {
      font-weight: 500;
      margin: 0;
      @include flex(unset, unset, center, unset);
    }

    &--right {
      font-weight: 700;
      margin: 0;
    }
  }

  .order-container__checkin {
    .checkin-div {
      margin: 0rem 1rem;
    }

    @include tablet {
      display: flex;

      .order-success {
        width: 50%;
      }

      .checkin-div {
        width: 50%;
        text-align: right;
      }
    }
  }

  .order-container__button,
  .order-container__button--problem {
    padding: 0rem;
    text-decoration: underline;
    border: none;
    background-color: $white;
    cursor: pointer;
    padding: 0.25rem 0 0 0;
  }

  .order-container__button--problem {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .order-container__icon {
    height: 30px;
    width: 30px;
    margin: auto 1rem;

    svg {
      fill: $secondary-colour;
      width: 100%;
    }

    @include tablet {
      display: flex;
      align-items: center;
      margin: auto 1rem;
    }
  }

  .order-container__stars {
    display: flex;
    margin-left: 0.5rem;

    .filled {
      svg {
        height: 25px;
        width: 25px;
        cursor: pointer;
        margin-left: 0.2rem;
        fill: $secondary-colour;
        stroke: $secondary-colour;
      }
    }

    .unfilled {
      svg {
        height: 25px;
        width: 25px;
        cursor: pointer;
        margin-left: 0.2rem;
        fill: $white;
        stroke: $secondary-colour;
      }
    }

    .disabled {
      svg {
        cursor: default;
      }
    }
  }

  .order-container__restaurant {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    @include tablet {
      flex-direction: row;
      padding-bottom: 0;
    }
    .order-container__restaurant-informations {
      display: flex;
      flex: 1;
    }
    &__shop {
      height: 30px;
      width: 30px;
      margin: auto;
    }
  }

  .order-container__details {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .order-container__paragraph {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    p {
      margin: 0;
    }

    @include tablet {
      justify-content: center;
      align-items: flex-start;
    }
  }

  .order-container__estimated-time {
    border-bottom: solid 0.14rem $grey-10;
    border-top: solid 0.14rem $grey-10;
    width: 100%;
    @include flex(column, unset, unset, unset);

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .estimated-time__content {
      @include flex(row, unset, unset, unset);
    }

    .estimated-time__button-container {
      @include flex(unset, center, unset, unset);
    }

    .estimated-time__button {
      font-size: 1rem;
      margin: 1rem auto;
      text-transform: none;
      width: initial;
    }

    svg {
      height: 30px;
      width: 30px;
      margin: 0.8rem 1rem;
      fill: $secondary-colour;
    }

    p {
      margin: auto auto auto 0rem;
    }
  }

  .order-container__rating,
  .order-container__rating--problem-mobile,
  .order-container__test {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 0.14rem $grey-10;
    padding: 0.2rem 1rem 0.8rem;

    p {
      font-weight: bold;
      margin: auto 0rem;
    }

    .order-container__rating--content {
      display: flex;
      align-items: center;
    }

    .order-container__reported--desktop {
      display: none;
      @extend .txt-xs;
      @extend .txt-success;

      @include tablet {
        display: block;
      }
    }

    .order-container__reported {
      @extend .txt-xs;
      @extend .txt-success;
    }
  }

  .order-container__test {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .order-container__rating--problem-mobile {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include tablet {
      display: none;
    }
  }

  .order-rate__msg__apply {
    @extend .txt-grey-50;
    @extend .txt-italic;
    @extend .txt-xs;
    position: relative;
    left: 15px;
    font-weight: normal !important;
    -webkit-animation: rateSuccess 5s linear both;
    animation: rateSuccess 5s linear both;
    opacity: 0;
  }

  @-webkit-keyframes rateSuccess {
    20% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes rateSuccess {
    20% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .order-container__orders {
    max-height: 0px;
    transition: max-height 0.4s linear;
    overflow: hidden;
  }

  .show {
    height: auto;
    max-height: 2000px;
  }

  .hide {
    overflow: hidden;
    max-height: 0px;
  }

  .order-container__details {
    width: 100%;
    @include flex(unset, space-between, center, wrap);
  }

  .order-items__loading-container {
    width: 100%;
    margin-top: 45px;
    justify-content: center;
    display: flex;
  }

  .order-reorder {
    @extend .txt-black;
    height: 70%;
    margin: auto;
    width: 210px;
    &:hover {
      @extend .txt-white;
    }
  }
}
