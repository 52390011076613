.search {
  padding: rem(20);

  .search__field {
    width: 100%;
    height: 41px;
    border-radius: rem(4);
    display: flex;
  }

  .search__input {
    flex-grow: 1;
    border: none;
    padding: rem(14) rem(10);
    background-color: transparent;

    &::placeholder {
      @extend .txt-grey-40;
    }

    &:focus {
      outline: none;
    }
  }

  .search__button {
    border: none;
    background-color: transparent;
    padding: 0;
    width: 41px;
    cursor: pointer;
    position: relative;

    &:disabled {
      opacity: .3;

      &:hover {
        color: $grey-80;
      }
    }

    &--search {
      &:hover {
        color: $primary-colour;
      }
    }

    &--clear {
      &::before {
        content: '|';
        @include absolute(unset, unset, unset, rem(-2));
        line-height: 1;
        @extend .txt-grey-30;
      }

      &:hover {
        color: $error-colour;
      }
    }
  }
}
