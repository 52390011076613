.map-container {
  height: 400px;
  border-bottom: solid rem(.1) $grey-10;

  @include laptop {
    height: auto;
    width: 100%;
  }

  &__tooltip {
    position: relative;
    padding: 1.25rem;

    &__title {
      border-bottom: solid rem(.1) $grey-10;

      p {
        margin: 0;
        font-weight: bold;
        @extend .txt-s;

        &:last-child {
          padding-bottom: 0.5rem
        }

        span {
          padding-left: .4rem
        }

        @include laptop {
          @include font-size-m;
        }

      }
    }

    &__links {
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @extend .txt-s;

      @include tablet {
        flex-direction: row;
        @include font-size-m;
      }
    }

    @include laptop {}
  }

  .tooltip-details {
    margin: auto auto 0.5rem auto;

    @include tablet {
      margin: auto rem(16) auto 0px;
    }

    &__distance {
      display: flex;
    }

    &__info {
      color: $primary-colour;
      text-decoration: underline;
      background: none;
      border: none;
      font-weight: bold;
      padding: 0;
      @extend .txt-s;

      @include laptop {
        @include font-size-m;
      }
    }

    span {
      font-weight: bold;
      color: $secondary-colour;
    }
  }

  .pick-restaurant__container {
    display: flex;
    justify-content: center;
  }
}

.gm-ui-hover-effect {
  margin: 1rem !important;
}
