.sum-basket-item {
  margin: 1rem 0;
  display: flex;

  @include tablet {
    display: flex;
    margin-bottom: 0;
  }

  &__wrap-img {
    margin-right: 1rem;
    position: relative;
    width: 76px;
    height: 76px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;

    &__image {
      width: auto;
      height: 100%;
    }

    @include tablet {
      width: 100px;
      height: 100px;
    }
  }

  &__text {
    max-width: 70%;

    @include tablet {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: space-between;
    }

    &__first-line {
      display: flex;
      align-items: center;

      .unavailable__label {
        margin-left: 4px;
      }

      span {
        margin-right: 0.6rem;
        font-weight: 700
      }

      p {
        text-transform: uppercase;
      }

      .txt-striked {
        text-decoration: line-through;
      }

    }

    &__second-line {
      p {
        margin: 0.8rem 0
      }
    }

    &__third-line {
      display: flex;
      justify-content: flex-end;

      p {
        text-align: right;
        font-weight: 600;
        padding-right: 0.4rem;
      }
    }
  }

  p {
    margin: 0
  }

  .round {
    background: $black;
  }

  .list__container--no-style {
    padding-left: .21rem;
    margin-top: .5rem;
  }
}
