// Shadow -------------------------------------------------------
$themeBoxShadow: 1px 1px 2px #0000003e;

// Radius -------------------------------------------------------
$themeBorderRadius: 0;

// Colours -------------------------------------------------------
$white: #fff;
$black: #000;
$theme-bg: #fff;

// GREY
$grey-10: #e3e3e3;
$grey-20: #cccccc;
$grey-30: #b0b0b0;
$grey-40: #999999;
$grey-50: #808080;
$grey-60: #696969;
$grey-70: #4d4d4d;
$grey-80: #363636;
$grey-90: #171717;

// PRIMARY
$primary-colour-light: #ff6000;
$primary-colour: #ff6000;
$primary-colour-dark: black;

// SECONDARY
$secondary-colour-light: #f5f1ef;
$secondary-colour: #202020;
$secondary-colour-dark: #202020;

// TERTIARY
$tertiary-colour-light: #fff;
$tertiary-colour: #f5f1ef;
$tertiary-colour-dark: #e5e0e0;

// SUCCESS
$success-colour: #04a349;

// ERROR
$error-colour: #bb0202;

// WARNING
$warning-colour-bg: #fcf5e6;
$warning-colour: #de5e00;

// ORDER STATES
$state-created-bg: $primary-colour-dark;
$state-created-text: $grey-90;
$state-validated-bg: #2196f3;
$state-validated-text: $white;
$state-sent-bg: #009688;
$state-sent-text: $white;
$state-paid-bg: $success-colour;
$state-paid-text: $white;
$state-error-bg: $error-colour;
$state-error-text: $white;
$state-warning-bg: $warning-colour;
$state-warning-text: $white;

// INPUT
$input-border-color: $grey-60;
$input-placeholder-color: $grey-60;
$input-border-radius: 0;
$input-border: solid 1px $input-border-color;
$input-bg-color: $tertiary-colour;

// BUTTONS - MAIN
$primary-button-border: none;
$primary-button-txt: $tertiary-colour-light;
$primary-button-background: $primary-colour;
// BUTTONS - HOVER
$primary-button-border-hover: none;
$primary-button-txt-hover: $grey-90;
$primary-button-background-hover: $primary-colour-light;
// BUTTONS - DISABLED
$primary-button-border-disabled: solid 3px $grey-30;
$primary-button-txt-disabled: $grey-80;
$primary-button-background-disabled: $grey-30;
$primary-button-opacity-disabled: 0.5;

// BORDERS
$themeBorderStyle: 5px solid $primary-colour;
$tertiary-border-light: 1px solid $tertiary-colour-light;
$tertiary-border: 1px solid $tertiary-colour;
$tertiary-border-dark: 1px solid $tertiary-colour-dark;
$secondary-border-light: 1px solid $secondary-colour-light;
$secondary-border: 1px solid $secondary-colour;
$secondary-border-dark: 1px solid $secondary-colour-dark;

// OTHERS
$google-primary-color: #1b73e7;
$facebook-primary-color: #1f449b;

// Breakpoints
// Used at base/_breakpoints.scss
// $mobile: 320;
$mobile: 480;
$tablet: 768; // need to be 712 instead 768 because of samsung s4 tablet
$laptop: 992;
$desktop: 1200;
$bigscreen: 1600;
// Usage: $unit (px, em, rem etc). Default unit is pixels.
$unit: px;

// Content containers
$mobileContainer: 460;
$tabletContainer: 748;
$laptopContainer: 960;
$desktopContainer: 1660;
