.order-page {
  min-height: calc(100% - 127px);

  @include tablet {
    min-height: calc(100% - 100px); // remove header & footer height
  }
  .order-page__container {
    width: 100%;
    .loading-div {
      height: 75%;
    }
  }
}
