.cta-font {
  font-family: "Mr Eaves";
  @extend .txt-bold;
}

.btn__primary {
  border: none;
  border-radius: $themeBorderRadius;
  border: $primary-button-border;
  color: $primary-button-txt;
  width: 100%;
  background-color: $primary-button-background;
  padding: rem(14) rem(27);
  border-radius: 0;
  max-width: 340px;
  @extend .cta-font;
  @extend .txt-l;
  @extend .txt-uppercase;
  transition: 0.3s;
  cursor: pointer;

  &--icon-before {
    &.btn__primary--icon-fas {
      @include font-icon(
        "fas",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        color: inherit;
        @extend .txt-primary;
        @extend .txt-l;
        transition: 0.3s;
      }
    }

    &.btn__primary--icon-far {
      @include font-icon(
        "far",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        color: inherit;
        @extend .txt-primary;
        @extend .txt-l;
      }
    }

    &.btn__primary--icon-fab {
      @include font-icon(
        "fab",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        color: inherit;
        @extend .txt-primary;
        @extend .txt-l;
      }
    }
  }

  &--icon-after {
    &.btn__primary--icon-fas {
      @include font-icon(
        "fas",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        color: inherit;
        @extend .txt-primary;
        @extend .txt-l;
        transition: 0.3s;
      }
    }

    &.btn__primary--icon-far {
      @include font-icon(
        "far",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        color: inherit;
        @extend .txt-primary;
        @extend .txt-l;
      }
    }

    &.btn__primary--icon-fab {
      @include font-icon(
        "fab",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        color: inherit;
        @extend .txt-primary;
        @extend .txt-l;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: $primary-button-border-hover;
    color: $primary-button-txt-hover;
    background-color: $primary-button-background-hover;

    // &::before,
    // &::after {
    //   color: $white;
    // }
  }

  &:active {
    background-color: $primary-colour-dark;
    @extend .txt-white;
    border: $primary-button-border-hover;
    color: $grey-90;
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: initial;
    color: $primary-button-txt-disabled;
    background-color: $primary-button-background-disabled;
    opacity: $primary-button-opacity-disabled;

    &::before,
    &::after {
      color: $grey-40;
    }

    &:hover {
      color: $primary-button-txt-disabled;
      background-color: $primary-button-background-disabled;
      opacity: $primary-button-opacity-disabled;
    }
  }
}

.btn__primary--inverse {
  @extend .cta-font;
  @extend .btn__primary;
  border-radius: 2rem;

  &:hover {
    background-color: $primary-colour;
    border-color: $primary-colour;
    color: $tertiary-colour;
  }
}

.btn__secondary {
  border: none;
  border-radius: 0;
  @extend .bg-primary;
  padding: rem(14) rem(27);
  width: auto;
  max-width: 340px;
  @extend .cta-font;
  @extend .txt-white;
  @extend .txt-s;
  @extend .txt-uppercase;
  transition: 0.3s;
  cursor: pointer;

  &--icon-before {
    color: $tertiary-colour;

    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        @extend .txt-white;
        @extend .txt-s;
        transition: 0.3s;
      }
    }

    &.btn__secondary--icon-far {
      @include font-icon(
        "far",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        @extend .txt-white;
        @extend .txt-s;
      }
    }

    &.btn__secondary--icon-fab {
      @include font-icon(
        "fab",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        @extend .txt-white;
        @extend .txt-s;
      }
    }
  }

  &--icon-after {
    color: $tertiary-colour;

    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        @extend .txt-white;
        @extend .txt-s;
        transition: 0.3s;
      }
    }

    &.btn__secondary--icon-far {
      @include font-icon(
        "far",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        @extend .txt-white;
        @extend .txt-s;
      }
    }

    &.btn__secondary--icon-fab {
      @include font-icon(
        "fab",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        @extend .txt-white;
        @extend .txt-s;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $primary-colour-dark;
  }

  &:active,
  &:focus {
    &::before {
      color: $tertiary-colour-dark;
    }

    background-color: $primary-colour;
    border: none;
  }

  &:focus {
    outline: 0;
    background-color: $primary-colour;
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    @extend .bg-secondary-light;
    @extend .txt-secondary;

    &::before,
    &::after {
      color: $secondary-colour;
    }
  }
}

.btn__secondary--inverse {
  border: 1px solid $secondary-colour-dark;
  @extend .bg-transparent;
  padding: rem(14) rem(27);
  @extend .cta-font;
  @extend .txt-secondary-dark;
  @extend .txt-s;
  @extend .txt-uppercase;
  transition: 0.3s;

  &.btn__secondary--icon-before {
    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        @extend .txt-s;
        transition: 0.3s;
      }
    }

    &.btn__secondary--icon-far {
      @include font-icon(
        "far",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        @extend .txt-s;
      }
    }

    &.btn__secondary--icon-fab {
      @include font-icon(
        "fab",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(14);
        @extend .txt-secondary-dark;
        @extend .txt-s;
      }
    }
  }

  &.btn__secondary--icon-after {
    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        @extend .txt-secondary-dark;
        @extend .txt-s;
        transition: 0.3s;
      }
    }

    &.btn__secondary--icon-far {
      @include font-icon(
        "far",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        @extend .txt-secondary-dark;
        @extend .txt-s;
      }
    }

    &.btn__secondary--icon-fab {
      @include font-icon(
        "fab",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-left: rem(14);
        @extend .txt-secondary-dark;
        @extend .txt-s;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $secondary-colour-dark;
    @extend .txt-white;
  }

  &:active {
    background-color: $secondary-colour;
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    @extend .bg-transparent;
    @extend .txt-secondary-dark;
    opacity: 0.5;

    &::before,
    &::after {
      @extend .txt-secondary-dark;
    }
  }
}

.btn__secondary--mini-inverse {
  border: 1px solid $secondary-colour-dark;
  border-radius: 2rem;
  @extend .bg-transparent;
  padding: rem(14) rem(27);
  width: 100%;
  max-width: 280px;
  @extend .cta-font;
  @extend .txt-secondary-dark;
  @extend .txt-xs;
  @extend .txt-uppercase;
  transition: 0.3s;

  &.btn__secondary--icon-before {
    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        @extend .txt-xs;
        transition: 0.3s;
      }
    }

    &.btn__secondary--icon-far {
      @include font-icon(
        "far",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(8);
        @extend .txt-xs;
      }
    }

    &.btn__secondary--icon-fab {
      @include font-icon(
        "fab",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(8);
        @extend .txt-secondary-dark;
        @extend .txt-xs;
      }
    }
  }

  &.btn__secondary--icon-after {
    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        @extend .txt-xs;
        transition: 0.3s;
      }
    }

    &.btn__primary--icon-far {
      @include font-icon(
        "far",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(8);
        @extend .txt-xs;
      }
    }

    &.btn__secondary--icon-fab {
      @include font-icon(
        "fab",
        "after",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        margin-right: rem(8);
        @extend .txt-xs;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $secondary-colour-dark;
    @extend .txt-white;
  }

  &:active {
    background-color: $secondary-colour;
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    @extend .bg-transparent;
    @extend .txt-secondary-dark;
    opacity: 0.5;

    &::before,
    &::after {
      @extend .txt-secondary-dark;
    }
  }
}

.btn__secondary--inverse,
.btn__secondary--mini-inverse {
  border-radius: 0;
  font-size: rem(20);
  &.btn__secondary--icon-before {
    &.btn__secondary--icon-fas {
      &:hover {
        &::before {
          @extend .txt-white;
        }
      }

      &:disabled:hover {
        &::before {
          @extend .txt-secondary-dark;
        }
      }
    }

    &.btn__secondary--icon-far {
      &:hover {
        &::before {
          @extend .txt-white;
        }
      }

      &:disabled:hover {
        &::before {
          @extend .txt-secondary-dark;
        }
      }
    }

    &.btn__secondary--icon-fab {
      &:hover {
        &::before {
          @extend .txt-white;
        }
      }

      &:disabled:hover {
        &::before {
          @extend .txt-secondary-dark;
        }
      }
    }
  }

  &.btn__secondary--icon-after {
    &.btn__secondary--icon-fas {
      &:hover {
        &::after {
          @extend .txt-white;
        }
      }

      &:disabled:hover {
        &::after {
          @extend .txt-secondary-dark;
        }
      }
    }

    &.btn__primary--icon-far {
      &:hover {
        &::after {
          @extend .txt-white;
        }
      }

      &:disabled:hover {
        &::after {
          @extend .txt-secondary-dark;
        }
      }
    }

    &.btn__secondary--icon-fab {
      &:hover {
        &::after {
          @extend .txt-white;
        }
      }

      &:disabled:hover {
        &::after {
          @extend .txt-secondary-dark;
        }
      }
    }
  }
}

.btn-padding {
  width: fit-content;
  width: -moz-fit-content;
  // max-width: fit-content;
  // max-width: -moz-fit-content;
  min-width: max-content;
  // min-width: -moz-fit-content;
}

.btn-round__primary {
  @include absolute(10px, 10px, unset, unset);
  @extend .btn__secondary;
  @extend .btn__secondary--icon-before;
  @extend .btn__secondary--icon-fas;
  max-width: rem(40);
  height: rem(40);
  padding: 0;
  line-height: 1;
  width: 100%;

  &.btn__secondary--icon-before {
    &.btn__secondary--icon-fas {
      @include font-icon(
        "fas",
        "before",
        attr(theme-icon),
        unset,
        unset,
        unset,
        unset
      ) {
        @extend .txt-xs;
        transition: 0.3s;
      }

      &::before {
        margin: 0;
        @extend .txt-s;
      }
    }
  }
}

.btn-round__secondary {
  @extend .btn-round__primary;
  background-color: transparent;
  border: 2px solid $secondary-colour;
  border-radius: 2rem;
  line-height: 1;

  &.btn__secondary--icon-before {
    &::before {
      color: $secondary-colour;
    }
  }

  &:hover {
    background-color: $secondary-colour;

    &.btn__secondary--icon-before {
      &::before {
        color: $tertiary-colour;
      }
    }
  }
}

.btn__error {
  @extend .btn__secondary;
  @extend .bg-error;
  box-sizing: border-box;

  &:hover {
    @extend .txt-error;
    background-color: transparent;
    border: 1px solid $error-colour;
  }
}
