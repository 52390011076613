.delete-account {
  .delete-account__title {
    max-width: 410px;
    margin: 1rem auto;
  }

  .delete-account__action {
    text-align: center;
    margin: 3rem auto 2rem;
  }

  .delete-account__button {
    margin: 0 .5rem .5rem;
    max-width: 40%;

    &--no {
      margin-bottom: 1rem;
    }

    &--yes {
      @extend .txt-secondary-dark;
      &:hover {
        @extend .txt-white;
      }
    }

    @include tablet {
      margin: 0 1rem 1rem;
      max-width: 12rem;
    }

    @include desktop {
      margin: auto 1rem;
    }
  }
}
