.no-available-product-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .no-available-product-modal__warning {
    margin: auto;
    width: 90px;
    img {
      width: 100%;
    }
  }
  .no-available-product-modal__button {
    margin: auto;
    button {
      @extend .btn-padding;
      @extend .home-main__btn
    }
  }
}
