.product-info {
  .product-info__container {
    @include tablet {
      @include flex(unset, flex-start, flex-start, nowrap);
    }
  }

  .product-info__figure {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    @include mobile {
      width: 400px;
      height: 400px;
      padding-bottom: initial;
    }

    @include tablet {
      width: rem(320);
      height: rem(320);
      margin: 0 1rem 0 0;
    }
  }

  .product-info__img {
    width: 100%;
    height: auto;
    @include middle-image;
  }

  .product-info__content {
    flex-grow: 1;
    position: relative;

    .product-info__name {
      @extend .txt-secondary-dark;
      font-size: rem(30);
      margin-top: 0;

      @include tablet {
        max-width: 80%;
      }
    }

    @include tablet {
      @include flex(column, unset, unset, unset);
      min-height: rem(320);
    }

    .parts__qty-wrapper {
      align-items: flex-start;
    }

    .btn__primary {
      margin-top: rem(25);
      min-width: 251px;

      @include tablet {
        @include absolute(unset, 0, 0, unset);
      }
    }
  }

  .product-info__description {
    margin-top: 0;

    @include tablet {
      flex-grow: 1;
      font-family: "Mr Eaves", sans-serif;
      font-size: rem(30);
      font-weight: 400;
      text-transform: none;
      font-style: normal;
    }
  }

  .product-info__price {
    @include tablet {
      @include absolute(0, 0, unset, unset);
    }

    span {
      @extend .txt-xl;
      @extend .txt-lemibold;
      @extend .txt-secondary;
    }
  }
}
