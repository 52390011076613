.home-main {
  background-color: white;
  background-image: url("../../../../../../src/assets/images/bg-little-caesar.png");
  background-repeat: repeat;

  @include laptop {
    min-height: calc(100vh - 260px);
  }

  .home-main__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: normal;

    @include laptop {
      flex-direction: row;
      height: 100% !important;

      & > div {
        flex: 1;
      }
    }

    .home-main__login {
      padding: 0;
      height: 100%;

      @include laptop {
        padding: 15px 40px;
      }

      .profile {
        background-repeat: no-repeat;
        background-size: auto 70%;
        background-position: bottom right;
        background-image: url("../../../../../../src/assets/images/pizza.png");
        background-color: $primary-colour;
        height: auto;
        padding: rem(40) rem(20);
        justify-content: flex-start;

        @include laptop {
          height: calc((68vw * (9 / 16)) - 4.5rem);
        }

        .profile__title.txt-h1 {
          color: white;
          font-size: rem(40);
          margin-top: 0;

          @include laptop {
            margin-top: rem(40);
          }
        }

        .profile__subtitle.txt-h3 {
          color: black;
          font-size: rem(30);
        }
      }

      .restaurants__search.search {
        width: 100%;
      }
    }

    @include laptop {
      flex-direction: row;
      align-items: start;
      width: 100%;

      .home-main__login {
        border: none;
        flex: 1 1;
      }
    }

    @include desktop {
      width: 100%;
    }

    @include bigscreen {
      width: 100%;
    }

    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      background-color: white;
      border: 3px solid;
      opacity: 1;

      @include laptop {
        width: 20px;
        height: 20px;
        border: 3px solid;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $primary-colour;
      border: 3px solid;
    }

    .my-custom-pagination-div {
      position: absolute;
      right: 0;
      bottom: 10px;
      left: auto;
      z-index: 1;
      width: auto;
      -webkit-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
    }

    .home-main__content__left__swiper {
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 2rem;

      @include laptop {
        width: 100%;
      }
    }

    .home-main__content__left {
      @include flex(column, center, center, unset);
      width: 100%;

      @include laptop {
        width: 68%;
      }

      .home-main__content__left__title {
        margin-top: 2rem;
        min-width: 300px;

        @include laptop {
          margin: 20px 0 16px;
        }
      }
    }
  }
}
