@import './animation';

.navigation__dropdown {
  margin-top: rem(12);
  background-color: $white;
  border-radius: rem(4);
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 24%);
  position: relative;
  z-index: 2;
  @extend .txt-uppercase;

  &.is-open {
    border-radius: 0.25rem 0.25rem 0 0;

    .navigation__menu {
      animation: open-dropdown .3s ease-out forwards;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: white;
      }
  
      &::-webkit-scrollbar:vertical {
        width: 8px;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: $grey-20;
        border: 2px solid white;
        border-radius: 8px;
      }
    }
  }

  .Dropdown-option {
    background-color: $white;
    border-right: 1px solid $grey-10;
    border-top: 1px solid $tertiary-colour-dark;
    padding: rem(15) rem(12);
    color: $secondary-colour;
    @extend .txt-uppercase;
  }

  .navigation__control {
    padding: rem(15) rem(12);
    display: flex;
    justify-content: space-between;
  }

  .navigation__placeholder {
    color: $primary-colour-dark;
    font-weight: bold;
  }

  .navigation__menu {
    overflow-y: scroll;
    border-bottom-left-radius: rem(4);
    border-bottom-right-radius: rem(4);
    position: absolute;
    width: 100%;
    box-shadow: 0 4px 4px 0 #0000001a;
    background-color: $white;
  }

  .arrow {
    border: solid $secondary-colour;
    border-width: 0 rem(2) rem(2) 0;
    display: inline-block;
    padding: 3px;
    transition: .3s;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);

    &.arrow--closed {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
