.discount__container {
  @include desktop {
    width: 100%;
    max-width: 100%;
  }

  .discount__container__label {
    padding: 0;
    width: 100%;
    @extend .txt-s;
    @extend .txt-bold;
    @extend .txt-secondary;

    @include desktop {
      width: 30%;
      font-family: "Mr Eaves", sans-serif;
      font-size: rem(20);
      font-weight: 400;
      text-align: right;
    }
  }

  .form-input__container--discount {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;

    @include desktop {
      flex-direction: row;
      align-items: center;
    }
  }

  .form-input__border--discount {
    margin-top: 1rem;

    @include desktop {
      margin-left: 10px;
    }
  }

  input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .discount-button__container {
    padding: 0 1rem 1rem;

    .discount-button__container__btn {
      max-width: 100%;
      margin-left: 10px;

      @include desktop {
        margin: 0;
        margin-left: 10px;
      }
    }
  }

  .form__discount__error {
    padding: 0 1rem;
    margin-bottom: 1rem;

    @include desktop {
      width: min-content;
      margin-left: auto;
      min-width: 350px;
    }
  }
}

.coupon-error {
  @extend .txt-error;
  @extend .txt-bold;
  padding-left: 1rem;
}

.checkout-page .checkout-form .form-input__action.discount-button__container {
  width: calc(70% - 10px);
  text-align: right;
  margin: 0 0 0 auto;
  padding: 0;

  button {
    width: 100%;
    max-width: none;
  }
}
