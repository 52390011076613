.reorder-success {
  .reorder-success__buttons {
    display: flex;
    justify-content: center;
    button {
      @extend .btn-padding;
      @extend .home-main__btn
    }
  }
}
