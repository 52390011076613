// Fade in animation pages
main {
  animation: fadeInAnimation ease-in-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeInAnimation {
  0% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInAnimationTransparency {
  0% {
      opacity: 0;
  }
  100% {
      opacity: .8;
  }
}
