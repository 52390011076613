.modal-show {
  overflow: hidden;

  .modal__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: .8;
    z-index: 11;
  }

  .modal__close {
    position: absolute;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1rem;
    right: 1rem;
    cursor: pointer;

    @include font-icon('fas', 'before', '\f00d', unset, unset, unset, unset) {
      @extend .txt-s;
      color: $secondary-colour-dark;
    }

    &:hover::before {
      color: black;
    }
  }

  .modal__box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    min-width: 70%;
    max-height: 80vh;
    background-color: $white;
    z-index: 11;
    border-radius: $themeBorderRadius;
    padding: 1.5rem;

    @include laptop {
      padding: 3rem;
      min-width: 50%;
    }
  }
}
