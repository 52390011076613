.discount__container {
  @include desktop {
    max-width: 480px;
  }

  .discount__container__label {
    padding: 0 1.1rem 0 0;
    width: 100%;
    @extend .txt-s;
    @extend .txt-bold;
    @extend .txt-secondary;

    @include desktop {
      width: 90px;
    }
  }

  .form-input__container--discount {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @include desktop {
      flex-direction: row;
      align-items: center;
    }
  }

  .form-input__border--discount {
    margin-top: 1rem;

    @include desktop {
      margin: 0;
    }
  }

  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .discount-button__container {
    padding: 0 1rem 1rem;

    .discount-button__container__btn {
      @include desktop {
        margin: 0;
        margin-left: auto;
        max-width: 350px;
      }
    }
  }

  .form__discount__error {
    padding: 0 1rem;
    margin-bottom: 1rem;

    @include desktop {
      width: min-content;
      margin-left: auto;
      min-width: 350px;
    }
  }

}

.coupon-error {
  @extend .txt-error;
  @extend .txt-bold;
  padding-left: 1rem;
}
