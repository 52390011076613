.checkout-page {
  width: 86%;
  .checkout-form {
    .form-input__action {
      padding: 0 1rem 1rem;
    }
  }
}

.checkout-page-main {
  background-color: $secondary-colour-light;
  .checkout-page-left__content-wrapper {
    box-shadow: none;
    border-radius: 0;
  }
}

.checkout-page__title {
  margin-top: rem(50);
}

.checkout-page__restaurant-closed {
  text-align: center;
  margin-top: 4rem;
}

.checkout-page__body {
  display: flex;
  margin: rem(20) auto rem(50) auto;
  flex-direction: column;

  @include laptop {
    flex-direction: row-reverse;
  }
}

.checkout-page__column {
  flex-grow: 1;

  @include laptop {
    box-sizing: border-box;
  }

  &.checkout-page__column--left {
    @include laptop {
      padding-right: rem(20);
      max-width: 55%;
    }
  }

  &.checkout-page__column--right {
    @include laptop {
      padding-left: rem(20);
      max-width: 45%;
    }
  }
}

.checkout-page__basket {
  background-color:$secondary-colour-dark;
  min-width: 200px;
  z-index: 11;
  animation: slide-left .3s ease-out forwards;
  overflow-y: auto;
  padding: 2rem;
  max-height: 100%;
  margin-bottom: rem(20);

  .modal__body {
    height: 100%;
  }

  .basket-item {
    background-color: $secondary-colour-light;
    border-radius: 0;
  }

  .reacp-text {
    font-style: italic;
  }
  
  .basket-header {
    border-bottom: 1px solid $secondary-colour-light;
  }

  .basket-footer__section {
    border-top: 1px solid $secondary-colour-light;
    .txt-secondary {
      color: $secondary-colour-light;
    }
  }
}

.checkout-page-left__content-wrapper {
  background-color: $white;
  border-radius: rem(4);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;

  .checkout-page__time-details {
    margin: 0;
    @extend .txt-secondary;
    @extend .txt-xs;

    &:last-child {
      @extend .txt-bold;
    }
  }
}

.content-wrapper--padding {
  padding: rem(10);
}
