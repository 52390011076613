.auth-status {
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
  @include flex(column, center, center, unset);

  &--error {
    .auth-status__title {
      @extend .txt-error;
    }

    .auth-status__icon {
      @extend .bg-error;
    }
  }

  .auth-status__title {
    text-transform: uppercase;
    margin-bottom: rem(10);
  }

  .auth-status__icon {
    width: 76px;
    height: 76px;
    @extend .bg-primary;
    border-radius: 50%;
    margin: 1rem 0;
    @include flex(row, center, center, unset);

    @include font-icon("fas", "before", attr(status-icon), unset, unset, unset, unset) {
      @extend .txt-xxl;
      @extend .txt-white;
    }
  }
}
