#cookiefirst-policy-page {
  // Even if it's not a good practice to add html element like this, I did it to override cookie first style
  h2 {
    @extend  .txt-primary;
    @extend .txt-m;
    @extend .txt-uppercase;
    margin-top: 2rem;
  } 
  h3 {
    @extend .txt-secondary-dark;
    @extend .txt-s;
    @extend .txt-uppercase;
    margin-top: 1rem;
  }

  th, td, svg {
    @extend .txt-secondary;
  }

  button {
    @extend .btn__primary;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

.cookiefirst-root {
  display: flex !important;
  @include flex(row, center, center, unset);
}
