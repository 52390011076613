// BasketItem.tsx

.basket-item {
  display: flex;
  flex-direction: column;
  margin: rem(12) auto;
  padding: rem(12) auto;
  color: $black;
  background-color: $tertiary-colour-dark;
  border-radius: rem(4);
}

// BasketItemTop.tsx

.basket-item__top {
  display: flex;
  flex-direction: row;
  position: relative;

  .btn__secondary--icon-before.basket-item__delete {
    height: rem(34);
    width: rem(34);
    position: relative;
    top: 0;
    right: 0;
    display: block;
    justify-content: center;
    text-align: center;

    &::before {
      @extend .txt-s;
      display: block;
      margin: auto;
    }
  }

  .svg-icon {
    margin: 0.2rem 0rem;
    position: relative;

    .svg-icon__text {
      font-size: 0.7rem;
      margin: auto .4rem;
      background: none;
    }

    .svg-icon__button {
      @include flex(row, unset, unset, unset);
      cursor: pointer;
      border: none;
      padding: 0rem;
      background: none;
    }

    .svg-icon__svg {
      font-size: rem(11);
      transition: .3s ease;
      transform: rotate(0deg);
      margin: auto 0;

      &.open {
        transform: rotate(180deg);
      }

      &.link {
        transform: rotate(270deg);
      }
    }
  }
}

.basket-item__parts {
  max-height: 0px;
  transition: max-height 0.6s ease-in-out;
  overflow: hidden;
}

.show {
  height: auto;
  max-height: 300px;
}

.hide {
  overflow: hidden;
  max-height: 0px;
}

.basket-item__infos {
  padding: rem(11);
  flex: 1;

  &__top {
    width: 100%;
    @include flex(row, space-between, flex-start, unset);
  }

  h2 {
    margin: 0;
  }

  &__delete {
    padding-left: .4rem
  }
}

.basket-item__figure {
  width: 4rem;
  height: 4rem;
  min-height: rem(88);
  position: relative;
  overflow: hidden;
  margin: 0;
  padding-left: .6875rem;

  .basket-item__img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

// BasketItemParts.tsx
.basket-item__parts-list {
  padding-left: 0;
  margin: 0;
  margin-bottom: rem(10);
  margin-left: 1rem;

  li {
    list-style: none;
  }
}

// BasketItemBottom.rsx

.basket-item__bottom {
  display: flex;
  flex-direction: column;
  padding: rem(11) rem(11);
  border-top: 1px solid $primary-colour;

  .basket-item__bottom--quantity {
    flex: 1;
    @include flex(row, space-between, center, nowrap);

    h2 {
      margin: 0 0 0 1rem;
      @extend .txt-regular;
    }

    .quantity-picker__container {
      margin-right: 0;
    }
  }

  .basket-item__bottom--total {
    margin-left: auto;
  }

  .warning-message__text {
    margin-top: 0;
  }
}
