.home-menu {
  height: 100%;

  .home-menu__profile {
    display: flex;
    flex-direction: column;

    p {
      text-align: left;
      margin: 0.5rem 0;
      font-weight: 600;
      text-transform: capitalize;
    }

    &__btn {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      background: none;
      border: none;
      border-bottom: solid 1px $tertiary-colour-dark;
      cursor: pointer;

      &__icon {
        height: 2rem;
        width: 2rem;
        background-color: $secondary-colour;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include font-icon('fas', 'before', '\f011', unset, unset, unset, unset) {
          @extend .txt-m;
          @extend .txt-white;
        }
      }

      p {
        margin-left: 1rem;
        text-transform: uppercase;
        color: $primary-colour;
        font-weight: 700
      }
    }
  }

  .home-menu__links {
    width: 100%;
    color: $tertiary-colour;
    text-transform: uppercase;
    font-weight: 700;
  }

  .menu-item-li {
    width: 100%;
    height: 50px;
    list-style-type: none;
    border-bottom: solid 1px $tertiary-colour-dark;

    &__link {
      width: 100%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      color: $secondary-colour;

      &:hover {
        color: $primary-colour;
        line-height: 0.5rem;
      }
    }
  }

  .home-menu__language {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: '▾';
      position: absolute;
      left: 23px;
      top: 12px;
      color: $secondary-colour;
      @extend .txt-l;

      @-moz-document url-prefix() {
        left: 28px;
      }
    }
  }

  .home-menu__language-text {
    color: $secondary-colour;
    text-transform: uppercase;
    font-weight: 700;
    border: none;
    background-color: transparent;
    z-index: 99;
    padding: .5rem 1.5rem .5rem 0;
    cursor: pointer;
    padding-right: 1.5em;
    -webkit-appearance: none;

    @-moz-document url-prefix() {
      padding-right: .9em;
    }

    &:focus {
      outline: none
    }
  }

  .home-menu__language-flag {
    width: 18px;
    height: 18px;
    margin-left: 0.4rem;
    left: 2.8rem;
  }
}
