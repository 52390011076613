@font-face {
  // for navigation and title sections
  font-family: "Mr Eaves";
  src: url("../fonts/MrEavesXLSanNarOT-Ultra.woff2");
  font-weight: 700;
  font-display: block;
}

@font-face {
  // for navigation and title sections
  font-family: "Mr Eaves";
  src: url("../fonts/MrEavesXLSanNarOT-Reg.woff2");
  font-weight: 400;
  font-display: block;
}

@font-face {
  // for navigation and title sections
  font-family: "LCScript";
  src: url("../fonts/LCScript-Regular.woff2");
  font-weight: 400;
  font-display: block;
}

* {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: $defaultfontsize;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  outline: none;
  font-feature-settings: "kern", "liga", "clig", "calt";
}

// SIZES -------------------------------------------------
.txt-xxs {
  font-size: rem(10);
}

.txt-xs {
  font-size: rem(12);
}

.txt-s {
  font-size: rem(14);
}

.txt-m {
  font-size: rem(16);
}

.txt-l {
  font-size: rem(18);
}

.txt-xl {
  font-size: rem(20);
}

.txt-xxl {
  font-size: rem(32);
  font-family: "Mr Eaves";
}

.footer__button {
  text-transform: none;
}
