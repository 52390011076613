/**
  * important: this styles will never change, so to change it override on the theme folder.
  * important: to add new class here ask for the project lead before or do it on the theme folder.
  */

.txt-center {
  text-align: center;
}

.txt-xleft {
  text-align: left;
}

.txt-right {
  text-align: right;
}
