.check {
  color: white;
  width: 60px;
  height: 60px;
  line-height: 18px;
  border-radius: 50%;
  position: relative;
  margin: auto;
  @include flex(unset, center, center, unset);

  &::before {
    @extend .txt-l;
  }

  &--success {
    background: $success-colour;

    @include font-icon('fas', 'before', '\f00c', unset, unset, unset, unset) {
      @extend .txt-xs;
      @extend .txt-white;
    }
  }

  &--failed {
    background: $error-colour;

    @include font-icon('fas', 'before', '\f00d', unset, unset, unset, unset) {
      @extend .txt-xs;
      @extend .txt-white;
    }
  }
  
}
