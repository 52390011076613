.footer {
  background-color: $secondary-colour;
  min-height: 50px;
  width: 100%;
  padding-bottom: 0;
  transition: 0.5s;

  @include tablet {
    height: 50px;
  }

  &--tablet-padding {
    @include max-laptop {
      // same height of restaurants__action button 118px
      padding-bottom: 118px;
    }
  }
  &--padding {
    padding-bottom: 102px;
  }

  .footer__container {
    @include flex(unset, space-between, center, wrap);
    height: 100%;
    margin: 0;
    padding: 1.2rem 1rem 1.1rem;

    @include tablet {
      margin: auto;
      padding: 0;
    }
  }

  .footer-block {
    @include flex(row, flex-start, center, nowrap);
    padding: 10px;

    a {
      text-decoration: none;
    }
  }

  .footer-block__center {
    order: 3;

    @include tablet {
      order: unset;
      margin-top: 3px;
      padding-bottom: rem(20);
    }

    p {
      color: $grey-10;
      @extend .txt-xs;
      margin: 0;
      font-weight: 400;
    }
  }

  .footer-block__left {
    justify-content: flex-start;
    width: 80%;
    order: 1;
    margin-top: 4px;

    @include tablet {
      width: auto;
      order: unset;
      margin-top: 5px;
    }

    .margin-right {
      margin-right: 10px;

      @include smallscreen {
        margin-right: 5px;
      }
    }
  }

  .footer-block__right {
    margin-right: 10px;
    justify-content: flex-end;

    order: 2;

    @include tablet {
      order: unset;
      margin-top: 4px;
    }

    @include smallscreen {
      width: max-content;
      margin-right: 5px;
    }
  }

  .footer__text {
    text-transform: uppercase;
    @extend .txt-s;
    font-weight: 700;

    @include smallscreen {
      @include font-size-xxs;
    }
  }
}

.footer__button {
  @extend .txt-tertiary-dark;
  @extend .txt-uppercase;
  @extend .txt-xs;

  &:hover {
    @extend .txt-tertiary-dark;
    cursor: pointer;
    opacity: 0.6;
  }
}
