.contact-form {
  textarea {
    min-height: 300px;
    resize: none;
  }

  .contact-row {
    display: flex;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }
  }

  .form-input__container--column {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .contact__container {
    @include desktop {
      width: 100%;
    }

    .discount__container__label {
      padding: 0 1.1rem 0 0;
      width: 100%;
      @extend .txt-s;
      @extend .txt-bold;
      @extend .txt-secondary;

      @include desktop {
        width: 90px;
      }
    }

    .form-input__container--discount {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      @include desktop {
        flex-direction: row;
        align-items: center;
      }
    }

    .form-input__border--discount {
      margin-top: 1rem;

      @include desktop {
        margin: 0;
      }
    }

    input[type="text"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .form-agree__container {
      margin: 10px 0px;
      display: block;

      .captcha-terms {
        @extend .txt-s;
      }
    }

    .input-agree__checkbox {
      position: relative;
      bottom: 2px;
      -webkit-appearance: none;
      vertical-align: middle;
      margin: 0 6px 0 0;
      height: 16px;
      width: 16px;
      outline: none;
      border: 2px solid $primary-colour;
      cursor: pointer;

      &.checked {
        box-shadow: 0 0 0px 2px $white inset;
        background: $primary-colour;
      }
    }

    .label-agree__checkbox {
      font-size: rem(15);
    }

    .discount-button__container {
      padding: 0 1rem 1rem;

      .discount-button__container__btn {
        @include desktop {
          margin: 0;
          margin-left: auto;
          max-width: 350px;
        }
      }
    }

    .contact-form__error {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }
}
