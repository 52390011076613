.modal__product-unavailable {
  .modal__close {
    display: none;
  }
}

.product-unavailable {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .product-unavailable__title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(22);
    text-align: center;
    margin-bottom: 35px;
  }

  .product-unavailable__tiles {
    display: inline-flex;
    flex-direction: row;
  }

  .product-unavailable__actions {
    margin: rem(20) auto;
    width: 100%;
    @include flex(column, center, center, unset);
    justify-content: center;

    @include tablet {
      flex-direction: column;
      max-width: 520px;
    }
  }

  .product-unavailable__replace {
    @extend .txt-black;
    &:hover {
      @extend .txt-white;
    }
  }

  .product-unavailable__btn {
    margin-top: rem(20);
    &--continue {
      margin-top: rem(20);

      @include tablet {
        margin-top: 0;
      }
    }
  }
}

.product-unavailable__visual-list {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .product-unavailable__image-container {
    position: relative;
    margin: 0px 15px;
    width: 120px; height: 120px;
    margin-bottom: 12px;
  }

  .product-unavailable__image-container img {
    width: 100%; height: 100%;
  }

  .product-unavailable__product-name {
    text-transform: uppercase;
    font-size: rem(13);
    text-align: center;
    margin-bottom: 20px;
  }
}
