.special-font {
  font-family: "Mr Eaves", sans-serif;
  @extend .txt-bold;
}

.txt-h1 {
  @extend .special-font;
  @extend .txt-xl;
}

.txt-h2 {
  @extend .special-font;
  @extend .txt-m;
}

.txt-h3 {
  @extend .special-font;
  @extend .txt-s;
  color: $secondary-colour;
}

.page-title {
  @extend .special-font;
  height: 3.6rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 1.2rem 0;
  @extend .txt-primary;
  @extend .txt-uppercase;
}
