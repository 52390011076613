.product__container {
  width: 100%;
  margin: 1rem;

  @include tablet {
    margin: 1rem auto;
  }

  .navigation__container {
    padding: 0;
  }
}

.product__total {
  font-family: "Mr Eaves", sans-serif;
  font-size: rem(25);

  span {
    @extend .txt-l;
    @extend .txt-lemibold;
    @extend .txt-grey-80;
    @extend .txt-secondary-dark;
  }

  margin-right: 1rem;
}
