.popover {

  &.popover__active--LEFT,
  &.popover__inactive--LEFT {
    .popover__background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($black, .8);
      z-index: 10;
    }

    .popover__box {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 230px;
      background-color: $tertiary-colour;
      z-index: 11;
      padding: rem(24);

      @include tablet {
        padding: 1.6rem;
      }

      .popover__close {
        @extend .close;
      }
    }
  }

  &.popover__active--LEFT {
    .popover__background {
      animation: fade-overlay .3s ease-out forwards;
    }

    .popover__box {
      animation: slide-right .3s ease-out forwards;
    }
  }

  &.popover__inactive--LEFT {
    .popover__background {
      animation: fade-overlay-out .3s ease-out forwards;
    }

    .popover__box {
      animation: slide-right-out .3s ease-out forwards;
    }
  }
}
