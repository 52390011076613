.modal__product-customization {

  .product-customization__title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: rem(22);
    text-align: center;
    margin-bottom: 35px;
  }

  .customization-center__position {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .customization-modal__wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 400px;
    width: inherit;
    max-width: 720px;

    /*  ---------> Uncomment the next lines to disable the vertical scrollbar /!\
    -ms-overflow-style: none;  // IE and Edge
    scrollbar-width: none;  // Firefox

    // Hide scrollbar for Chrome, Safari and Opera
    &::-webkit-scrollbar {
      display: none;
    } */

    .parts__item:last-child {
      border-bottom: none;
    }

    .parts__item {
      @include flex(unset, space-between, center, nowrap);
      padding: 5px 0px;
      width: 100%;
      border: none;
      outline: 0;
      border-bottom: 1px solid $grey-30;
      cursor: pointer;
      background-color: transparent;



      .parts__customization-container {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include tablet {
          flex-direction: row;
        }

        .parts__description {
          position: relative;
          display: flex;
          align-items: center;

          .parts__image-container {
            position: relative;
            width: 42px;
            height: 42px;
            margin-right: 15px;

            .parts__image {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .parts__customization_warning {
          margin: 0 10px;
        }

      }

      &:focus {
        background-color: $warning-colour-bg;
      }



      &--select {
        flex-direction: column;
        align-items: flex-start;

        .parts__list {
          width: -webkit-fill-available;
          padding: 0 0 0 rem(20);
        }

        li.parts__item {
          &:last-child {
            border: none;
          }
        }
      }

      &--group {
        padding: 0;

        .parts__title-wrap {
          background-color: $tertiary-colour-dark;
          padding: rem(12);
        }
      }

      .parts__title-wrap {
        width: 100%;
        @include flex(unset, unset, unset, wrap);
        align-items: center;

        &--required {
          background-color: $warning-colour-bg;
        }
      }
    }
  }

  .customization-modal__buttons {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
