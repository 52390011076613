.go-back {
  width: 100%;
  height: 3.2rem;
  @include flex(unset, unset, center, unset);
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: $secondary-border-dark;

  &__link {
    height: 100%;
    text-decoration: none;
    @include flex(unset, unset, baseline, unset);
    cursor: pointer;

    &__icon {
      height: 100%;
      @include flex(unset, center, center, unset);
      margin-right: 0.8rem;

      @include font-icon('fas', 'before', '\f015', unset, unset, unset, unset) {
        @extend .txt-xs;
        @extend .txt-black;
        color: $secondary-colour;
      }
    }

    &__text {
      display: flex;
      text-transform: uppercase;
      color: $secondary-colour;
      font-weight: 700;
      margin: 0
    }
  }
}
