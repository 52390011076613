.notification {
  position: relative;
  &[data-notification]::after {
    @include flex(unset, center, center, unset);
    content: attr(data-notification);
    position: absolute;
    top: 8px;
    right: 26px;
    background: $primary-colour;
    color: white;
    font-weight: bold;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 50%;
  }

  &.notification--left {
    &[data-notification]::after {
      right: unset;
      left: 0;
    }
  }
}
