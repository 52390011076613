.parts {
  margin-top: rem(30);
  // border-top: 2px solid $grey-30;
  padding-top: rem(30);
  width: 100%;
  margin-bottom: rem(50);

  .parts__header {
    @include flex(unset, space-between, center, nowrap);
  }

  .parts__title {
    // max-width: rem(180);
    word-break: break-all;
    margin-right: rem(10)
  }

  .modifiers__reset {
    border: none;
  }

  .parts__list {
    list-style: none;
    padding-left: 0;
    box-sizing: border-box;
    width: 100%;
  }

  .parts__item {
    @include flex(unset, space-between, center, nowrap);
    padding: .5rem 0;
    min-height: 50px;
    width: 100%;
    border: none;
    outline: 0;
    border-bottom: 1px solid $grey-30;
    cursor: pointer;
    font-weight: normal;

    &:focus {
      background-color: $warning-colour-bg;
    }

    .parts__customization-container {
      display: flex;
      align-items: center;

      .parts__description {
        position: relative;
        display: flex;
        align-items: center;

        .parts__image-container {
          position: relative;
          width: 42px;
          height: 42px;
          margin-right: 15px;

          .parts__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }


    &--select {
      flex-direction: column;
      align-items: flex-start;

      .parts__list {
        width: -webkit-fill-available;
        padding: 0 0 0 rem(20);
      }

      li.parts__item {
        &:last-child {
          border: none;
        }
      }
    }

    &--group {
      padding: 0;

      .parts__title-wrap {
        background-color: $tertiary-colour-dark;
        padding: rem(12);
      }
    }

    .parts__title-wrap {
      width: 100%;
      @include flex(unset, unset, unset, wrap);
      align-items: center;

      &--required {
        background-color: $warning-colour-bg;
      }
    }
  }

  .parts__name-wrap {
    @include flex(unset, unset, center, wrap);
  }

  .parts__name {
    padding-top: rem(2);
    margin-right: .5rem;
    flex: 1;

    @include mobile {
      flex: unset;
    }
  }

  .warning-message {
    margin-right: auto;

    .warning-message__text {
      margin: 0 rem(15) 0 0;
    }
  }

  .parts__warning {
    @include flex(unset, unset, center, unset);
    margin-right: rem(10);
    margin-left: auto;
  }

  .parts__price {
    span {
      @extend .txt-regular;
      @extend .txt-s;
    }
  }

  .parts__expand-btn {
    padding-top: rem(2);
    background-color: transparent;
    display: flex;
    margin-right: auto;

    .arrow {
      border: none;
      background: none;
      margin-left: .5rem;
      transform: rotate(0);
      transition: 0.5s;
      color: $primary-colour;
    }

    @include mobile {
      flex: 1;
      text-align: left;
    }
  }

  .parts__item--open {
    .parts__expand-btn {
      cursor: pointer;

      .arrow {
        transform: rotate(-180deg);
      }
    }
  }
}

.parts__qty-wrapper {
  @include flex(column, unset, flex-end, nowrap);

  .txt-warning {
    margin: 0;
  }
}

.dispatcher {
  margin-left: 1rem;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;

  &--first-level {
    height: auto;
    max-height: max-content;
    transition: max-height 0.5s ease-in-out;
    margin-left: 0;
  }

  &--open {
    height: auto;
    max-height: 1000px;

    .parts__item {
      background-color: $tertiary-colour-light;
      transition: all 0.5s ease-in-out;

      .radio-button {
        pointer-events: auto;
      }

      .parts__qty-wrapper {
        pointer-events: auto;
      }

      &:hover {
        transition: all 0.5s ease-in-out;
        background-color: $warning-colour-bg;
      }

      &--active {
        transition: all 0.5s ease-in-out;
        background-color: $warning-colour-bg !important;
      }
    }
  }
}
