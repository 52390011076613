@keyframes fade-overlay {
  from {
    opacity: 0;
  }

  to {
    opacity: .8;
  }
}

@keyframes fade-overlay-out {
  from {
    opacity: .8;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-left {
  from {
    // modal_box width + modal__box padding = 240 + (24 * 2)
    right: -288px;
  }

  to {
    right: 0;
  }
}

@keyframes slide-left-out {
  from {
    right: 0;
  }

  to {
    // modal_box width + modal__box padding = 240 + (24 * 2)
    right: -288px;
  }
}

@keyframes slide-right {
  from {
    // modal_box width + modal__box padding = 230 + (24 * 2)
    left: -278px;
  }

  to {
    left: 0;
  }
}

@keyframes slide-right-out {
  from {
    left: 0;
  }

  to {
    // modal_box width + modal__box padding = 230 + (24 * 2)
    left: -278px;
  }
}
