.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: relative;

  .not-found__image {
    height: 18%;
    @include absolute(unset, 15%, 0, unset);
  }

  p {
    margin: 0;
    font-weight: 900;
  }

  .link {
    margin-top: rem(20);
    line-height: 1.5;
  }

  .title {
    display: flex;
    align-items: center;

    .title__text {
      font-size: rem(48);
      font-weight: 900;
      line-height: 1.2;
    }

    .title__image {
      width: rem(100);
      height: rem(100);
      margin-right: rem(10);
    }
  }

}
