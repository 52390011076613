/**
  * important: this styles will never change, so to change it override on the theme folder.
  * important: to add new class here ask for the project lead before or do it on the theme folder.
  */
// STYLES ------------------------------------------------

.txt-xlight {
  font-weight: 300;
}

.txt-regular {
  font-weight: 400;
}

.txt-lemibold {
  font-weight: 600;
}

.txt-bold {
  font-weight: 700;
}

.txt-decoration-unset {
  text-decoration: none;
}

.txt-extrabold {
  font-weight: 800;
}

.txt-italic {
  font-style: italic;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-underline {
  text-decoration: underline;
}

// SIZES -------------------------------------------------
.txt-xxs {
  font-size: rem(10);
}

.txt-xs {
  font-size: rem(12);
}

.txt-s {
  font-size: rem(14);
}

.txt-m {
  font-size: rem(16);
}

.txt-l {
  font-size: rem(18);
}

.txt-xl {
  font-size: rem(20);
}

.txt-xxl {
  font-size: rem(32);
}
