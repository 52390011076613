.legals__container {
  margin: 1rem;

  @include tablet {
    margin: 1rem auto;
  }
}

.legals__body {
  &--section {
    margin-top: 2rem;
  }
}

.legals__link {
  color: $primary-colour;
}

.legals__title {
  @extend .txt-bold;
  @extend  .txt-primary;
  @extend .txt-uppercase;
  @extend .txt-xxl;

  margin-top: 2rem;
}

.legals__subtitle {
  @extend  .txt-primary;
  @extend .txt-uppercase;
  @extend .txt-xl;
}

.legals__text {
  white-space: break-spaces;
  @extend .txt-l;
}
